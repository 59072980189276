<template>
    <el-row style="width: 100%;">
        <el-col :xs="24" :md="24" class="lf_el-col">
			<div class="lf_rd_select_box">
				<div class="lf_1280">
					<img v-if="systemlang=='cn'" class="lf_rd_select_box_adimg" src="../../src/assets/images/u923.jpg" alt="">
					<img v-if="systemlang=='en'" class="lf_rd_select_box_adimg" src="../../src/assets/images/club_ad05.jpg" alt="">
					<img v-if="systemlang=='tw'" class="lf_rd_select_box_adimg" src="../../src/assets/images/club_ad04.jpg" alt="">
					<template  v-if="matchList.length > 0">
					<el-select class="lf_select_match_box" @change="changeMatch" v-model="mt_id">


						

						<el-option-group
						v-for="group in matchList"
						:key="group.id"
						:label="group.title">
						<el-option
						  v-for="abs in group.data"
						  :key="abs.mt_id"
						  :label="abs.name"
						  :value="abs.mt_id">
						</el-option>
					</el-option-group>
					</el-select>
					</template>
					<div class="lf_match_box">
						{{ match!=undefined&&match.mt_id!=undefined?match.mt_cn_name:"" }}
					</div>
					<div class="lf_rd_change_btn_box" v-if="match!=undefined&&match.mt_id!=undefined">
						<div class="lf_rd_change_box">
							<div v-for="(i,index) in roundarr" :class="(i==rd_id?'lf_rd_change_btn_select':'')+' '+(i<=match.mt_round?'':'choosedisabled')"  @click="changeround(i)"> 
								{{langs.text_di}} {{numarr[i]}} {{(matchmode=="SRSQ"||matchmode=="SRLQ")&&match_set.pk&&match_set.pk[rd_id-1].scoretype==1?langs.text_jieduan:langs.text_lun}}
							</div>
						</div>
						<div class="lf_type_change_right">
							<el-select class="lf_select_type_rank_box lf_1281" @change="changebygroup" v-model="bygroup">
								<el-option
								  v-for="item in [{value:0,label:langs.text_ranking},{value:1,label:langs.text_Group}]"
								  :key="item.value"
								  :label="item.label"
								  :value="item.value">
								</el-option>
							</el-select>
							<div class="lf_daochu_type_box">
								<el-select class="lf_select_match_type_rank_box lf_1281" v-model="matchmode" @change="changematchmode">
									<el-option
									  	v-for="item in matchmodelist"
											:key="item.value"
											:label="item.label"
											:value="item.value">
									</el-option>
								</el-select>
								<!-- <div class="lf_daochu_btn" @click="reportToHolebyHoleExcel">{{langs.text_export}}</div> -->
								<div class="lf_daochu_btn" @click="centerDialogVisible = true">{{langs.text_export}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="lf_score_box">
				<div class="lf_tips_box_A">
					<div v-if="match.mt_id!=33579&&match_set.mt_amateur=='(A)'">{{langs.Amateur}}</div>
					<div>{{langs.StartfromBacknine}}</div>
					<div><span>F</span>  {{langs.Confirmed}}</div>
					<div @click="hidecodeimg()" v-if="!show&&urlData.phoneUrl" id="lf_box_min" ref="infoBoxmin" class="info-box_min">
						<img :src="urlData.phoneUrl" alt="">
						<div>{{langs.Scantoviewscores}}</div>
					</div>
				</div>
				<div class="lf_zgjg_btn_box" v-if="matchmode!='SRSQ'&&matchmode!='SRLQ'">
					<div class="lf_zgjg_btn_box_left">
						<div @click="changemodal('');" :class="modal=='NetScore'||leader==1?'':'lf_zgjg_btn_box_left_select'" v-if="match_set.mt_scoring_mode=='NNP'||match_set.mt_scoring_mode=='ZBCD'||match_set.mt_scoring_mode=='NNP_ZBCD'">
							{{langs.text_TotalScore}}
							<img src="../assets/images/u946.png" alt="">
						</div>
						<div @click="changemodal('NetScore')" :class="modal=='NetScore'?'lf_zgjg_btn_box_left_select':''" v-if="match_set.mt_scoring_mode=='NNP'||match_set.mt_scoring_mode=='ZBCD'||match_set.mt_scoring_mode=='NNP_ZBCD'">
							{{langs.text_NetScore}}
							<img src="../assets/images/u946.png" alt="">
						</div>

						<div @click="changeleader('');" :class="modal=='NetScore'||leader==1?'':'lf_zgjg_btn_box_left_select'" v-if="match_set.mt_scoring_mode=='BGS'">
							{{langs.text_perhole}}
							<img src="../assets/images/u946.png" alt="">
						</div>
						<div @click="changeleader('1')" :class="leader==1?'lf_zgjg_btn_box_left_select':''" v-if="match_set.mt_scoring_mode=='BGS'&&bygroup!=1">
							{{langs.text_leader}}
							<img src="../assets/images/u946.png" alt="">
						</div>
					</div>
					<!-- <div class="lf_zgjg_btn_box_left" v-else>
						<div class="lf_zgjg_btn_box_left_select">
							排位得分
							<img src="../assets/images/u946.png" alt="">
						</div>
					</div> -->
					<div class="lf_zgjg_btn_box_right" v-if="glist.length>0 &&bygroup!=1">
						<el-select class="lf_select_match_type_rank_box" v-model="gid" @change="changegid" placeholder="请选择">
							<el-option
							  v-for="item in glist"
								:key="item.gid"
								:label="item.gname"
								:value="item.gid">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			
			<el-dialog :title="langs.ChoosetheviewofExcel+':'" v-model="centerDialogVisible" width="30%" center>
				<div class="lf_radio_box">
					<img src="../../src/assets/images/ExportButton1.png" @click="reportToHolebyHoleExcel(0)" alt="">
					<img src="../../src/assets/images/ExportButton2.png" @click="reportToHolebyHoleExcel(1)" alt="">
				</div>
				<!-- <span slot="footer" class="dialog-footer">
					<el-button @click="centerDialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
				</span> -->
			</el-dialog>

		</el-col>
	</el-row>
	<!-- v-if="show&&urlData.phoneUrl" -->
	<div id="lf_box" v-if="show&&urlData.phoneUrl" ref="infoBox" class="info-box" :style="styleObject">
		<img class="lf_close_img" @click="hidecodeimg()" src="../assets/images/ww.png" alt="">
		<div class="lf_code_img" @mousedown.stop="mouseDownHandler">
			<div>{{langs.Scantoviewscores}}</div>
			<img :src="urlData.phoneUrl" alt="">
		</div>
	</div>
</template>

<script>
	var setmatchclass = 0;
	 import axiosx from '../axios/postx';
	 import axios from '../axios/request';
	 import { getSystemLang, getLangAll } from "../lang";
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤'
				}],
				value: '',
				curyear:'',
				matchList:[],
				matchInfo:[],
				mt_id:'',
				numarr:["0","一","二","三","四","五","六","七","八","九"],
				roundarr:[],
				rd_id:'',
				bygroup:0,
				glist:[],
				gid:'',
				matchmodelist:[],
				matchmode:'',
				centerDialogVisible: false,
				styleObject: {
					width: "400px",
					height: "300px"
				},
				urlData:[],
				show: true,
				isMove:false,
		    }
		},
        name: 'select',
        props: {
            msg:String,
            flag:String,
			cd:String,//菜单
			year:String,
			match:Object,
			match_set:Object,
			modal:String,
			leader:String,
			// matchmode:String
        },
		watch:{
			match(newVal,oldVal){
				if(this.rd_id==''){
					this.rd_id = newVal.mt_round;
				}
				var roundarr = [];
				for(var i=1;i<=newVal.mt_round_num;i++){
					roundarr.push(i);
				}
				this.roundarr = roundarr;
				this.setmatchclass();
			}
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			console.log(this.langs)
			this.numarr = ["0",this.langs.yi,this.langs.er,this.langs.san,this.langs.si,this.langs.wu]
			var rd_id = this.$route.query.RoundID==undefined?'':this.$route.query.RoundID;
			this.bygroup = this.$route.query.bygroup==''||this.$route.query.bygroup==undefined?0:parseInt(this.$route.query.bygroup);
			this.gid = this.$route.query.g;
			this.listmode = this.$route.query.list;
			if(this.gid == '0'){
				if(this.listmode=='BGS'||this.listmode=='BDS'||this.listmode=='DFS'||this.listmode=='NNP'||this.listmode=='ZBCD'){
					this.gid = 'P';
				}else{
					this.gid = 'T';
				}
			}
			this.rd_id = rd_id;
			let currentUrl = window.location.href;
			currentUrl = encodeURIComponent(currentUrl);
			var roundarr = [];
			for(var i=1;i<=this.match.mt_round_num;i++){
				roundarr.push(i);
			}
			this.roundarr = roundarr;
			//获取banner
			axiosx('apply/geturl.json',{url:currentUrl,type:'1'})
			.then((res)=>{
				let data = res.data;
				
				if(data.msgCode=='200')
				{
					let surl = this.urlData = data.data;
					this.curyear = data.data.year;
					this.matchInfo = data.data.matchInfo;
					this.getMatchList(this.curyear,this.matchInfo.mt_club,this.matchInfo.mt_tour);
					this.mt_id = this.matchInfo.mt_id+'';
				}
				else
				{
				
				}
			});
			
		},
		mounted() {
		  this.styleObject = {
		    height: this.$props.height,
		    width: this.$props.width,
		  }
		},
		methods:{
			///v1/match/matchlist.json
			getMatchList(year,clubid,tourid,s){
				if(!clubid){clubid = this.matchInfo.mt_club}
				if(!tourid){tourid = this.matchInfo.mt_tour}
				axiosx('match/matchlist.json',{year:year,club_id:clubid,tour_id:tourid})
				.then((res)=>{
					let data = res.data;
					if(data.msgCode=='200')
					{
						this.matchList = data.data;
						if(s == 'go')
						{
							let routeUrl = this.$router.resolve({
								path: "/playerscore",
								query: {
									lang:this.systemlang,
									ClubID:this.matchInfo.mt_club,
									TourID:this.matchInfo.mt_tour,
									MatchID:data.go.mt_id,
									s:'match'
								}
							});
							window.open(routeUrl.href, '_self');
						}
					}
					else
					{
					
					}
				});
			},
			changeMatch(e){
				let routeUrl = this.$router.resolve({
					path: "/playerscore",
					query: {
						lang:this.systemlang,
						ClubID:this.matchInfo.mt_club,
						TourID:this.matchInfo.mt_tour,
						MatchID:e,
						s:'match'
					}
				});
				window.open(routeUrl.href, '_self');
			},
			changeround(round){
				if(round>this.match.mt_round){
					return;
				}
				var params = this.$route.query;
				params["RoundID"] = round;
				let routeUrl = this.$router.resolve({
					path: "/playerscore",
					query: params
				});
				window.open(routeUrl.href, '_self');
			},
			changebygroup(e){
				var params = this.$route.query;
				if(e==1){
					params["bygroup"] = 1;
				}else{
					params["bygroup"] = "";
				}
				let routeUrl = this.$router.resolve({
					path: "/playerscore",
					query: params
				});
				window.open(routeUrl.href, '_self');
			},
			setmatchclass(){
				var glist=[];
				var matchmodelist = [];
				var matchclass = this.match.class;
				if(this.match_set.match_per==1||this.match_set.match_tm==0){
					var str = "";
					// if(this.match_set.mt_scoring_mode=="BDS"){
					// 	str = "个人比洞";
					// }else if(this.match_set.mt_scoring_mode=="BGS"){
					// 	str = "个人总杆";
					// }else if(this.match_set.mt_scoring_mode=="DFS"){
					// 	str = "个人定分";
					// }else if(this.match_set.mt_scoring_mode=="NNP"){
					// 	str = "新新贝利亚";
					// }else if(this.match_set.mt_scoring_mode=="ZBCD"){
					// 	str = "自报差点";
					// }else if(this.match_set.mt_scoring_mode=="NNP_ZBCD"){
					// 	str = "自报差点";
					// }
					str = this.langs[this.match_set.mt_scoring_mode]
					if(this.match_set.mp_per==1){
						matchmodelist.push({value:"pk",label:str})
					}else{
						matchmodelist.push({value:this.match_set.mt_scoring_mode,label:str})
					}
					for(var k in matchclass){
						if(matchclass[k].gtype=='P'){
							matchclass[k]["gid"] = matchclass[k]["gid"]+'';
							matchclass[k]["gname"] = this.langs.gerensai+'---'+matchclass[k]["gname"];
							glist.push(matchclass[k]);
						}else{
							continue;
						}
					}
				}
				if(this.match_set.match_tm==1){
					var str = "";
					// if(this.match_set.score_type=="BDS"){
					// 	str = "个人比洞";
					// }else if(this.match_set.score_type=="LRLQ"){
					// 	str = "单人对抗";
					// }else if(this.match_set.score_type=="QNCJ"){
					// 	str = "团队总杆";
					// }else if(this.match_set.score_type=="SRLQ"){
					// 	str = "四人两球";
					// }else if(this.match_set.score_type=="SRSQ"){
					// 	str = "四人四球";
					// }else if(this.match_set.score_type=="TDDF"){
					// 	str = "团队定分";
					// }else if(this.match_set.score_type=="TNET"){
					// 	str = "团队净杆";
					// }else if(this.match_set.score_type=="TNET-NNP"){
					// 	str = "团队新新贝利亚";
					// }
					// this.match_set.mt_scoring_mode
					str = this.match_set.score_type_showname?this.match_set.score_type_showname:this.langs[this.match_set.score_type];
					if(this.match_set.mp_tm==1){
						matchmodelist.push({value:this.match_set.score_type,label:str})
					}else{
						matchmodelist.push({value:this.match_set.score_type,label:str})
					}
					for(var k in matchclass){
						if(matchclass[k].gtype=='T'){
							matchclass[k]["gid"] = matchclass[k]["gid"]+'';
							matchclass[k]["gname"] = this.langs.tuanduisai+'---'+matchclass[k]["gname"];
							glist.push(matchclass[k]);
						}else{
							continue;
						}
					}
				}
				
				console.log("matchmodelist",matchmodelist);
				this.matchmodelist = matchmodelist;
				var inmatchmodelist = 0;
				for(var k in matchmodelist){
					if(matchmodelist[k].value == this.listmode){
						inmatchmodelist = 1;
						break;
					}
				}
				if(this.listmode==undefined||inmatchmodelist==0){
					this.matchmode = matchmodelist[0].value;
				}else{
					this.matchmode = this.listmode;
				}
				if(this.listmode == "pk"){
					if(this.match_set.pk[this.rd_id-1].type=="SRLQ_BDS"&&this.match_set.pk[this.rd_id-1].scoretype==1){
						this.matchmode="LRYQ";
					}else if(this.match_set.pk[this.rd_id-1].type=="SRSQ_BDS"&&this.match_set.pk[this.rd_id-1].scoretype==1){
						this.matchmode="LRLQ";
					}
					this.matchmode = (this.matchmode=='LRLQ'?'SRSQ':(this.matchmode=='LRYQ'?'SRLQ':this.matchmode));
				}
				// console.log(this.matchmode,this.listmode)
				this.glist = glist;
				if(glist.length>0&&(this.gid==undefined||this.gid=='')){
					this.gid = glist[0]['gid'];
				}
			},
			changegid(e){
				var params = this.$route.query;
				if(e=='P'){
					params["g"] = "";
					params.list = this.match_set.mt_scoring_mode;
				}else if(e=='T'){
					params["g"] = "";
					params.list = this.match_set.score_type;
				}else{
					for(var k in this.glist){
						if(this.glist[k].gid == e){
							params["g"] = e;
							if(this.glist[k].gtype == "P"){
								params.list = this.match_set.mt_scoring_mode;
							}else if(this.glist[k].gtype == "T"){
								params.list = this.match_set.score_type;
							}
						}
					}
				}
				let routeUrl = this.$router.resolve({
					path: "/playerscore",
					query: params
				});
				window.open(routeUrl.href, '_self');
			},
			changemodal(modal){
				if(modal==this.modal){
					return;
				}
				var params = this.$route.query;
				params["modal"] = modal;
				let routeUrl = this.$router.resolve({
					path: "/playerscore",
					query: params
				});
				window.open(routeUrl.href, '_self');
			},
			changeleader(leader){
				if(leader==this.leader){
					return;
				}
				var params = this.$route.query;
				params["leader"] = leader;
				let routeUrl = this.$router.resolve({
					path: "/playerscore",
					query: params
				});
				window.open(routeUrl.href, '_self');
			},
			changematchmode(e){
				var params = this.$route.query;
				params["list"] = e;
				params["g"] = "";
				if(this.glist.length>0){
					if(e==this.match_set.score_type){
						var gtype = "T";
					}else if(e==this.match_set.mt_scoring_mode){
						var gtype = "P";
					}
					for(var k in this.match.class){
						if(this.match.class[k].gtype==gtype){
							params["g"] = this.match.class[k].gid;
							break;
						}
					}
				}
				let routeUrl = this.$router.resolve({
					path: "/playerscore",
					query: params
				});
				window.open(routeUrl.href, '_self');
			},
			reportToHolebyHoleExcel(iscolor){
				var mt_id = this.mt_id
				var rd_id = this.rd_id
				var gid = this.getQueryVariable('g');
				var modal=this.getQueryVariable('modal');
				var selv = '';
				// if(document.getElementById("mySelect2--3") )
				// {
				// 	selv = (document.getElementById("mySelect2--3").options[document.getElementById("mySelect2--3").selectedIndex].value);
				// }
				var list=this.getQueryVariable('list');
				var bygroup = "";
				var paramurl = 'mt_id='+mt_id+'&rd_id='+rd_id+'&list='+list+'&bygroup='+bygroup+'&gid='+gid+'&modal='+modal+'&iscolor='+iscolor;
				//'mt_id='+mt_id+'&rd_id='+rd_id+'&class_id='+gid+'&modal='+modal+'&iscolor='+iscolor;
				this.centerDialogVisible = false;
				if(modal!="NetScore"){
					window.open("https://www.wifigolf.com/customer/excel/new/template_1/excel_holebyhole_wifinew.php?"+paramurl);
				}else if(this.match_set.mt_scoring_mode == "NNP"||this.match_set.mt_scoring_mode == "NNP_ZBCD"){
					window.open("https://www.wifigolf.com/customer/excel/new/template_1/excel_holebyhole_nnp.php?"+paramurl);
				}else if(this.match_set.mt_scoring_mode == "ZBCD"){
					window.open("https://www.wifigolf.com/customer/excel/new/template_1/excel_holebyhole_zbcd.php?"+paramurl);
				}else{
					alert("暂无团队赛导出");
					// window.open('./excel/report/excel_team_new.php?mt_id='+mt_id+'&rd_id='+rd_id+'&class_id='+gid);
				}
				if(false&&(list=='BGS' || list=='' || list=='NNP')){
					window.open("https://www.wifigolf.com/customer/excel/new/template_1/excel_holebyhole.php?"+paramurl);
					// if(tmpdata['type']=='team' && (selv.indexOf('list=QNCJ')>0 || selv.indexOf('list=SRSQ')>0 || selv.indexOf('list=SRLQ')>0 || selv.indexOf('list=TNET')>0 || selv.indexOf('list=TDDF')>0)){
					// 	// window.open('./excel/report/excel_team_new.php?mt_id='+mt_id+'&rd_id='+rd_id+'&class_id='+gid);;
					// 	alert("暂无团队赛导出");
					// }else{
					// 	window.open("http://www.wifigolf.com/customer/excel/new/template_1/excel_holebyhole.php?"+'mt_id='+mt_id+'&rd_id='+rd_id+'&class_id='+gid+'&modal='+modal);
					// }
				}
			},
			getQueryVariable(variable)
			{
				var query = window.location.search.substring(1);
				var vars = query.split("&");
				for (var i=0;i<vars.length;i++) {
						var pair = vars[i].split("=");
						if(pair[0] == variable){return pair[1];}
				}
				return(false);
			},
			mouseDownHandler(e) {
				e.preventDefault();
			  const currentPosition = {x:this.$refs.infoBox.offsetLeft,y:this.$refs.infoBox.offsetTop};
			  const startPosition = {x:e.clientX,y:e.clientY};//获取当前点击位置
			  console.log(currentPosition,startPosition);
			  this.isMove = true;
			  //注册鼠标移动事件
			  document.addEventListener("mousemove",(event_move)=>{
				if(!this.isMove) return;
				  const offsetX = event_move.clientX - startPosition.x,
						offsetY = event_move.clientY - startPosition.y;
				  console.log(offsetX,offsetY)
				  //修改弹框位置
				  this.$refs.infoBox.style.left = `${currentPosition.x + offsetX}px`;
				  this.$refs.infoBox.style.top = `${currentPosition.y + offsetY}px`;
			  })
			  //注册鼠标抬起事件
			  document.addEventListener("mouseup",()=>{
				this.isMove = false;
			  })
					 
			},
			hidecodeimg(){
				this.show=!this.show;
			}
		}
    }
</script>
<style>
	html {
	-webkit-filter: grayscale(100%);
	filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
	}
	.lf_rd_select_box .el-input,.el-select{
		height: 100%;
		border-radius: 4px;
		font-size: 22px;
	}
	.lf_rd_select_box .select-trigger{
		height: 100%;
	}
	.lf_el-col .lf_rd_select_box .el-input.is-focus,.lf_el-col .lf_rd_select_box .el-input.is-focus .el-input__wrapper,.lf_el-col .lf_rd_select_box .el-input__wrapper{
		box-shadow: unset!important;
		background-color: unset;
	}
	.lf_el-col .el-input__inner{
		font-size: 16px;
	}
	/* 导出按钮 */
	.lf_daochu_type_box .el-input,.el-select{
		height: 100%;
		border-radius: 4px;
		font-size: 22px;
		border: unset;
	}
	.lf_daochu_type_box .select-trigger{
		height: 100%;
	}
	.lf_el-col .lf_daochu_type_box .el-input.is-focus,.lf_el-col .lf_daochu_type_box .el-input.is-focus .el-input__wrapper,.lf_el-col .lf_daochu_type_box .el-input__wrapper{
		box-shadow: unset!important;
		background-color: unset;
	}
	.lf_el-col .el-input__inner{
		font-size: 16px;
	}
	.lf_1280 .el-input__inner{
		color: #fff;
	}
	.lf_1281 .el-input__inner{
		color: var(--el-input-text-color,var(--el-text-color-regular));
	}
	
	
	
	.lf_zgjg_btn_box_right .el-input,.el-select{
		height: 100%;
		border-radius: 4px;
		font-size: 22px;
		border: unset;
	}
	.lf_zgjg_btn_box_right .select-trigger{
		height: 100%;
	}
	.lf_el-col .lf_zgjg_btn_box_right .el-input.is-focus,.lf_el-col .lf_zgjg_btn_box_right .el-input.is-focus .el-input__wrapper,.lf_el-col .lf_zgjg_btn_box_right .el-input__wrapper{
		box-shadow: unset!important;
		background-color: unset;
	}
	.lf_el-col .el-input__inner{
		font-size: 16px;
	}
	.el-select-group__title{
		background-color: rgba(245, 248, 254, 1);
		color: #0066CC!important;
		font-weight: bold!important;
		font-size: 18px!important;
		padding: 6px 10px!important;
		
	}
	.el-select-group__wrap:not(:last-of-type)::after{
		height: 0!important;
	}
	.el-select-group__wrap:not(:last-of-type){
		padding-bottom: 0!important;
	}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.lf_score_box{
			width: 1280px;
			margin: 0 auto;
		}
		.lf_tips_box_A{
			height: 50px;
			display: flex;
			align-items: center;
			font-size: 18px;
			color: #333;
			position: relative;
		}
		.lf_tips_box_A div{margin-right: 20px;}
		.lf_tips_box_A div span{
			color: #027512;
		}
		.lf_zgjg_btn_box{
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 1280px;
			height: 40px;
			background: inherit;
			background-color: rgba(240, 243, 246, 1);
			border: none;
			border-radius: 0px;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			box-shadow: none;
	}
		.lf_zgjg_btn_box_left{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.lf_zgjg_btn_box_left div{
			max-width: 120px;
			height: 40px;
			margin-left: 20px;
			font-size: 22px;
			color: #333;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.lf_zgjg_btn_box_left div img{
			display: none;
		}
		.lf_zgjg_btn_box_left .lf_zgjg_btn_box_left_select{
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 22px;
			color: #0066CC;
			text-align: center;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.lf_zgjg_btn_box_left .lf_zgjg_btn_box_left_select img{
			position: absolute;
			bottom: 0;
			width: 16px;
			height: 8px;
			display: block;
		}
    .lf_el-col{
		display: flex;
	}
	.lf_rd_select_box{
		width: 100%;
		height: 313px;
		background: inherit;
		background-color: rgba(32, 34, 66, 1);
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.lf_1280{
		max-width: 1280px;
		margin: 0 auto;
	}
	.lf_rd_select_box_adimg{
		max-width: 1280px;
		max-height: 87px;
		margin-top: 53px;
	}
	.lf_select_match_box{
		width: 1280px;
		height: 50px;
		background: inherit;
		background-color: rgba(255, 255, 255, 0.298039215686275);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(228, 228, 228, 1);
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.lf_match_box{
		width: fit-content;
		max-width: 1280px;
		height: 44px;
		background: inherit;
		background-color: rgba(255, 255, 255, 0);
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 36px;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		margin-top: 25px;
	}
	.lf_rd_change_btn_box{
		display: flex;
		align-items: center;
		max-width: 1280px;
		justify-content: space-between;
	}
	.lf_rd_change_box{
		width: fit-content;
		display: flex;
		align-items: center;
		margin-top: 8px;
		border: 1px solid #fff;
	}
	.lf_rd_change_box div{
		width: 120px;
		height: 40px;
		background: inherit;
		background-color: #797A8E;
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 22px;
		color: #FFFFFF;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid #fff;
		cursor: pointer;
	}
	.lf_rd_change_box div:last-of-type{
		border-right: none;
	}
	.lf_rd_change_box .lf_rd_change_btn_select{
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 22px;
		color: #000000;
		text-align: center;
		background-color: #fff;
	}
	.lf_type_change_right{
		display: flex;
		align-items: center;
	}
	.lf_select_type_rank_box{
		width: 112px;
		height: 40px;
		background: inherit;
		background-color: rgba(255, 255, 255, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(228, 228, 228, 1);
		border-radius: 20px;
		
	}
	.lf_daochu_type_box{
		margin-left: 20px;
		width: 265px;
		height: 40px;
		background: inherit;
		background-color: rgba(255, 255, 255, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(228, 228, 228, 1);
		border-radius: 20px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		display: flex;
		align-items: center;
		position: relative;
	}
	.lf_select_match_type_rank_box{
		width: 180px;
	}
	.lf_daochu_btn{
		width: 70px;
		height: 36px;
		background: inherit;
		background-color: rgba(255, 88, 96, 1);
		border: none;
		border-radius: 50px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 20px;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 1px;
		top: 1px;
		cursor: pointer;
}
.lf_el-col{
	flex-direction: column;
}
.lf_radio_box{
	display: flex;
	justify-content: center;
	height: 60px;
	background-color: #fff;
	justify-content: center;
	padding: 0 10px;
	border-radius: 10px;
}
.lf_radio_box img{
	width: 150px;
	height: 50px;
	margin: 0 10px;
	border-radius: 10px;
}
/* .lf_radio_box img{
	border: 2px solid #2c2c2c;
}
.lf_radio_box img:nth-last-of-type(1){
	border: 2px solid #ff5860;
} */
.dialog-footer{
	margin-top: 20px;
	display: flex;
	justify-content: center;
	border-top: 1px solid #efefef;
	padding-top: 10px;
}
.dialog-footer button {
	margin: 0 40px;
}
#lf_box{
		width: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: fixed;
		right: 10px;
		top: 50%;
		z-index: 999;
	}
	.lf_close_img{
		width: 40px;
		height: 40px;
		margin-bottom: 10px;
		cursor: pointer;
	}
	.lf_code_img{
		width: 150px;
		
	}
	.lf_code_img div{
		width: 150px;
		height: 40px;
		background: inherit;
		background-color: rgba(21, 117, 229, 1);
		display: flex;
		border: none;
		border-radius: 5px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 18px;
		align-items: center;
		justify-content: center;
		color: #fff;
		cursor: pointer;
	}
	.lf_code_img img{
		border: 1px solid rgba(21, 117, 229, 1);
		cursor: pointer;
	}
	#lf_box_min{
		width: 170px;
		height: 30px;
		background: inherit;
		background-color: rgba(240, 243, 246, 1);
		border: none;
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 18px;
		color: #1575E5;
		position: absolute;
		right: 0;
		top: 10px;
		display: flex;
		align-items: center;
		
	}
	#lf_box_min div{
		margin: 0!important;
	}
	#lf_box_min img{
		width: 26px;
		height: 26px;
		margin: 0 5px;
	}
    @media screen and (max-width: 768px) {
        
    }
</style>
