<template>
    <el-row style="width: 100%;">
        <el-col :xs="24" :md="24" class="lf_el-col" style="display: flex;position: relative;">
			<div class="lf_yard_box" :style="downflag?'height:40px':'height:fit-content'">
				<div class="lf_yard_tips">
					<span>{{ matchhole.cr_cn_name }}</span>
					-   Par<template v-for="(i,index) in matchhole.sum">{{ index!=0?"/":"" }}{{i.par}}</template>
					&nbsp;
					<template v-for="(g,gindex) in matchhole.sum">
						<template v-for="(i,index) in match.classindex[gindex].choose_mt_tee_arr.split(',')">{{ index!=0||gindex!=0?"/":"" }}{{matchhole.sum[gindex][i.toLowerCase()]}}</template>{{langs.text_Yard}}
					</template>
					<img @click="downflags" :class="downflag?'lf_yard_down_img':'lf_yard_down_img1'" src="../../src/assets/images/u1033.png" alt="">
				</div>
				<div class="lf_yard_con">
					<div class="lf_yard_hole">
						<div class="lf_1_tit">{{langs.Hole}}</div>
						<div class="lf_2_con_s" v-for="i in [1,2,3,4,5,6,7,8,9]">{{i}}</div>
						<div class="lf_yard_total_l">{{langs.text_Total}}</div>
					</div>
					<template v-for="(g,gindex) in matchhole.holelist">
						<div class="lf_yard_par">
							<div class="lf_1_tit">{{langs.text_Par}}</div>
							<div class="lf_2_con_s" v-for="i in [1,2,3,4,5,6,7,8,9]">{{matchhole.holelist[gindex][i-1]!=undefined?matchhole.holelist[gindex][i-1].par:''}}</div>
							<div class="lf_yard_total_l">{{ matchhole.sum[gindex].par }}</div>
						</div>
						<div  v-for="(item,index) in match.classindex[gindex].choose_mt_tee_arr.split(',')" :class="index%2!=0?'lf_yard_tee_back lf_yard_tee':'lf_yard_tee'">
							<div class="lf_1_tit">{{ matchhole.teename[item] }}</div>
							<div class="lf_2_con_s" v-for="i in [1,2,3,4,5,6,7,8,9]">{{ matchhole.holelist[gindex][i-1]!=undefined?matchhole.holelist[gindex][i-1][item.toLowerCase()]:'' }}</div>
							<div class="lf_yard_total_l">{{ matchhole.sum[gindex][item.toLowerCase()] }}</div>
						</div>
					</template>
				</div>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	 import axiosx from '../axios/postx';
	 import axios from '../axios/request';
	 import { getSystemLang, getLangAll } from "../lang";
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
				downflag:true,
				outpar:"",
				inpar:""
		    }
		},
        name: 'Btn',
        props: {
            msg: String,
            flag:String,
			cd:String  ,//菜单
			undergoing:String,
			match:Object,
			matchhole:Object
        },
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			var outpar = 0;
			var inpar = 0;
			var teearr = this.match.mt_tee_arr;
			teearr = teearr.split(',');
			for(var k in teearr){
				this.matchhole["out"+teearr[k]] = 0;
				this.matchhole["in"+teearr[k]] = 0;
			}
			for(var k in this.matchhole.hole){
				if(this.matchhole.hole[k].ho_id<=9){
					outpar += this.matchhole.hole[k].par;
					for(var key in teearr){
						this.matchhole["out"+teearr[key]] += this.matchhole.hole[k][teearr[key].toLowerCase()];
					}
				}else if(this.matchhole.hole[k].ho_id<=18&&this.matchhole.hole[k].ho_id>=10){
					inpar += this.matchhole.hole[k].par;
					for(var key in teearr){
						this.matchhole["in"+teearr[key]] += this.matchhole.hole[k][teearr[key].toLowerCase()];
					}
				}
			}
			this.outpar = outpar;
			this.inpar = inpar;
		},
		mounted(){
			
		},
		methods:{
			downflags(){
				var ff=this.downflag;
				this.downflag=!ff;
			}
		}
    }
</script>
<style>
	html {
	-webkit-filter: grayscale(100%);
	filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
	}
	
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .lf_yard_box{
		width: 1280px;
		overflow: hidden;
		margin-top: 20px;
	}
	.lf_yard_con{
		border-bottom: 2px solid #02AA70;
	}
	.lf_yard_tips{
		height: 40px;
		background: inherit;
		background-color: rgba(2, 117, 18, 1);
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 22px;
		color: #FFFFFF;
		text-align: left;
		display: flex;
		align-items: center;
		text-indent: 22px;
		position: relative;
	}
	.lf_yard_down_img{
		position: absolute;
		right: 20px;
		width: 30px;
		height: 30px;
		transform: rotate(180deg);
	}
	.lf_yard_down_img1{
		position: absolute;
		right: 20px;
		width: 30px;
		height: 30px;
		transform: rotate(0deg);
	}
	.lf_yard_hole,.lf_yard_par,.lf_yard_tee{
		display: flex;
		align-items: center;
	}
	.lf_yard_hole div,.lf_yard_par div,.lf_yard_tee div{
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
	}
	.lf_yard_hole{
		height: 33px;
		color: #fff;
		background: linear-gradient(to bottom, rgba(8,129,80) 13%,rgba(2,170,112,1) 20%, rgba(2,170,112,1) 100%);
	}
	.lf_yard_par{
		height: 33px;
		color: #fff;
		background-color: #74AE9C;
	}
	.lf_yard_tee{
		height: 50px;
		color: #333;
	}
	.lf_yard_tee:nth-of-type(2n){
		background-color: #EAEDF0;
	}
	
	.lf_1_tit{
		width: 269px;
		height: 100%;
		font-size: 22px!important;
		color: #02AA70;
		background-color: #EBF6FF;
	}
	.lf_2_con_s{
		width: 84px;
		height: 100%;
	}
	.lf_yard_oun_in{
		width: 118px;
		height: 100%;
		color: #02AA70;
		background-color: #EBF6FF;
	}
	.lf_yard_total_l{
		width: 137px;
		height: 100%;
		flex-grow: 1;
	}
	.lf_yard_par .lf_2_con_s,.lf_yard_hole .lf_2_con_s{
		color: #fff;
	}
	.lf_yard_hole .lf_1_tit,.lf_yard_par .lf_1_tit{
		color: #fff;
		background-color: unset!important;
	}
	.lf_yard_hole .lf_yard_oun_in,.lf_yard_par .lf_yard_oun_in{
		color: #fff;
		background-color: unset;
	}
	.lf_yard_hole .lf_yard_oun_in,.lf_yard_hole .lf_yard_total_l{
		font-size: 22px;
	}
	/* .lf_yard_tee .lf_1_tit{
		background-color: #EBF6FF!important;
	} */
	.lf_yard_tee_back .lf_1_tit,.lf_yard_tee_back .lf_yard_oun_in{
		background-color: #D8E3EE!important;
	}
    @media screen and (max-width: 768px) {
        
    }
</style>
