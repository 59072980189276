<template>
    <el-row style="width: 100%;">
        <el-col :xs="24" :md="24" class="lf_el-col" style="display: flex;position: relative;height: 70px;">
			<img class="lf_wifigolf_logo" src="../../src/assets/images/u892.png" alt="">
			<div class="lf_btn_box">
				<div @click="goWfigolf('match')">{{langs.Events}}</div>
				<div class="lf_btn_box_select">{{langs.MTScore}}</div>
				<div @click="goWfigolf('matchgroup')">{{langs.MTGroup}}</div>
				<div @click="goWfigolf('matchdata')">{{langs.DataCenter}}</div>
				<div @click="goWfigolf('player')">{{langs.text_player}}</div>
				<!-- <div>球员奖金榜</div>
				<div>球员积分榜</div> -->
				
			</div>
			<el-select class="lf_select_box" @change="changYear" v-model="year" placeholder="select">
			    <el-option
			      v-for="item in years"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			  </el-select>
		</el-col>
	</el-row>
</template>

<script>
	 import axiosx from '../axios/postx';
	 import axios from '../axios/request';
	 import { getSystemLang, getLangAll } from "../lang";
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
				urlData:[],
				years: [],
				year: ''
		    }
		},
        name: 'Btn',
        props: {
            msg: String,
            flag:String,
			cd:String  ,//菜单
			undergoing:String,
			gofunction:Function
        },
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);

			//处理年份
			
			

			let currentUrl = window.location.href;
			currentUrl = encodeURIComponent(currentUrl);
			//获取banner
			axiosx('apply/geturl.json',{url:currentUrl,type:'1'})
				.then((res)=>{
					let data = res.data;
					
					if(data.msgCode=='200')
					{
						
						this.urlData = data.data;
						this.years = data.data.years;
						this.year = data.data.year;
					}
					else
					{
					
					}
				});
			
		},
		mounted(){
			
		},
		methods:{
			goWfigolf($falg){
				var data = this.urlData;
				console.log(data)
				if(data[$falg])
				{
					window.open(data[$falg], '_self');
				}
				else
				{
					console.log('未找到地址');
				}
			},
			changYear(e)
			{
				this.gofunction(e);
			}
		}
    }
</script>
<style>
	html {
	-webkit-filter: grayscale(100%);
	filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
	}
	.lf_select_box .el-input,.el-select{
		border:2px solid rgba(255, 88, 96, 1);
		height: 100%;
		border-radius: 4px;
		font-size: 22px;
	}
	.lf_select_box .select-trigger{
		height: 100%;
	}
	.lf_el-col .lf_select_box .el-input.is-focus .el-input__wrapper,.lf_el-col .lf_select_box .el-input__wrapper{
		box-shadow: unset!important;
	}
	
	.lf_el-col .el-input__inner{
		font-size: 16px;
	}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .lf_wifigolf_logo{
		width: 390px;
		height: 102px;
		position: relative;
		z-index: 22;
	}
	.lf_btn_box{
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_btn_box div{
		width: 120px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 22px;
		text-align: center;
		color: #333;
		cursor: pointer;
	}
	.lf_btn_box div:hover,.lf_btn_box .lf_btn_box_select{
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 22px;
		color: #0066CC;
		text-align: center;
	}
	.el-select{
		width: 97px;
		height: 42px;
		line-height: 42px;
		background: inherit;
		border: none;
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '等线 Bold', '等线 Regular', '等线';
		font-weight: 700;
		font-style: normal;
		font-size: 22px;
		color: #FFFFFF;
		text-align: left;
		position: absolute;
		right: 30px;
		top: 14px;
	}
	
    @media screen and (max-width: 768px) {
        
    }
</style>
