<template>
    <el-row style="width: 100%;">
        <el-col :xs="24" :md="24" class="lf_el-col" style="display: flex;position: relative;flex-direction: column;">
			<div class="lf_Playoff_box">
				<div class="lf_Playoff_tit">
					<div class="lf_off_1">{{langs.PLAYOFF}} <text style="font-size: 20px;">PLAYOFF</text></div>
					
					<div class="lf_off_3" v-for="(b,index) in playoff"><text>#{{index+1}} </text> H{{ b.ho_id }} Par{{ b.score[0].par }}</div>
				</div>
				<div v-for="(item,index) in playoff[0]['score']" class="lf_Playoff_con">
					<div class="lf_off_1 " >
						<div :class="item.result=='WNR'?'lf_off_1_win':'lf_off_1_elm'">{{ item.result }}</div>
						<div class="lf_off_2">
							<img v-if="match_set.mt_country_show_type==1" class="lf_country_img" :src="'http://overview.wifigolf.com/images/flags/changurl.php?mt_id='+mt_id+'&content=64/'+ (item.player!=undefined&&item.player.pl_country?item.player.pl_country:'country')+'.png'" alt="">
							<span v-if="match_set.mt_country_show_type==2" class="lf_country_text">{{item.player!=undefined&&item.player.pl_country?item.player.pl_country:''}}</span>
							{{ item.player!=undefined?item.player.pl_cn_name:"" }}{{ match_set.mt_amateur=='(A)'&&item.player.pl_type=='AMA'?'(A)':'' }}
						</div>
					</div>
					<!-- lf_off_3_win  最终赢的人，字体加粗 -->
					<div class="lf_off_3" :class="(playerscore.score==undefined||playerscore.score[index]==undefined?'':(playerscore.score[index].to_par<0?'lf_score_2':(playerscore.score[index].to_par==0?'lf_score_3':'lf_score_4')))+' '+(item.result=='WNR'?'lf_off_3_win':'')" v-for="playerscore in playoff">{{playerscore.score[index].score}}</div>
				</div>
				
			</div>
			<div class="lf_full_L">
				全部排名 <text>FULL LEADERBOARD</text> 
			</div>
		</el-col>
	</el-row>
</template>

<script>
	import axiosx from '../axios/postx';
	import axios from '../axios/request';
	import { getSystemLang, getLangAll } from "../lang";
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
				downflag:true,
		    }
		},
        name: 'addHole',
        props: {
            msg: String,
            flag:String,
			cd:String  ,//菜单
			undergoing:String,
			playoff:Object,
			match_set:Object,
			mt_id:String
        },
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
		},
		mounted(){
			
		},
		methods:{
			downflags(){
				var ff=this.downflag;
				this.downflag=!ff;
			}
		}
    }
</script>
<style>
	html {
	-webkit-filter: grayscale(100%);
	filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
	}
	
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .lf_Playoff_box{
    	background-color: #FAFAFA;
		margin-top: 20px;
		border-bottom: 2px solid #B72A47;
    }
    .lf_Playoff_tit{
    	display: flex;
    	align-items: center;
    	width: 1280px;
		height: 40px;
		background: inherit;
		background-color: rgba(183, 42, 71, 1);
		border: none;
		border-radius: 0px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 22px;
		color: #FFFFFF;
    }
    .lf_Playoff_con{
    	height: 70px;
		display: flex;
		align-items: center;
		background-color: #FAFAFA;
    }
    .lf_off_1{
    	width: 330px;
    	font-size: 20px;
    	display: flex;
    	align-items: center;
    	height: 40px;
		text-indent: 22px;
    }
    .lf_off_1_win{
    	background-color: rgba(183, 42, 71, 1);
		border: none;
		border-radius: 20px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 22px;
		color: #FFFFFF;
		width: 100px;
		    height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-indent: 0;
    	
    }
	.lf_off_1_elm{
		width: 100px;
		    height: 40px;
		    background: inherit;
		    background-color: rgba(255, 255, 255, 1);
		    border: none;
		    border-radius: 20px;
		    border-top-left-radius: 0px;
		    border-bottom-left-radius: 0px;
		    -moz-box-shadow: none;
		    -webkit-box-shadow: none;
		    box-shadow: none;
		    font-family: '微软雅黑';
		    font-weight: 400;
		    font-style: normal;
		    font-size: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-indent: 0;
	}
    .lf_off_2{
    	font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 24px;
		display: flex;
		align-items: center;
		margin-left:10px;
    }
    .lf_off_2 img{
    	width: 30px;
    	height: 20px;
    	margin-right: 10px;
    }
	.lf_off_3{
		display: flex;
		width: 140px;
		align-items: center;
		justify-content: center;
		margin: 0 20px;
		font-family: 'Arial Normal', 'Arial';
		font-weight: 400;
		font-style: normal;
		color: rgba(255, 255, 255, 0.698039215686274);
		text-align: center;
		font-size: 18px;
	}
	 .lf_Playoff_tit .lf_off_3 text{
		font-size: 20px;
		color: #fff;
	}
    .lf_Playoff_con .lf_off_3{
    	display: flex;
    	width: 140px;
    	align-items: center;
    	justify-content: center;
		background-color: #fff;
		font-family: 'Arial Normal', 'Arial';
		font-weight: 400;
		font-style: normal;
		font-size: 28px;
		text-align: center;
		height: 100%;
    }
	.lf_Playoff_con .lf_off_3_win{
		font-weight: bold;
	}
	.lf_full_L{
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		color: #0E1B4D;
		font-size: 24px;
		height: 50px;
		display: flex;
		align-items: flex-end;
		text-indent: 20px;
	}
	.lf_full_L text{
		font-size: 20px;
	}
    @media screen and (max-width: 768px) {
        
    }
</style>
