<template>
	<div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.3)" style="height: 100%;">
		<div style="margin:0 auto;width:1280px;">
			<yard9 v-if="!islcd" :match="match" :matchhole="matchhole"></yard9>
		</div>
		<div class="lf_score_box" >
			<div class="lf_tit_list_box" ref="lf_main_box_top">
				<div class="lf_tit_list_box_tit">
					<div class="lf_1">{{langs.text_team}}</div>
					<div :class="'lf_'+(index+2)" v-for="(item,index) in teams">
					{{item["team_"+(systemlang=="cn"||systemlang=="tw"?"ch":"en")+"_name"]?item["team_"+(systemlang=="cn"||systemlang=="tw"?"ch":"en")+"_name"]:item["team_ch_name"]}}
					</div>
				</div>
				<div class="lf_tit_list_box_con">
					<div class="lf_1">{{langs.zongdefen}}</div>
					<div :class="'lf_'+(index+2)" v-for="(item,index) in teams">
						<template v-if="zongfenkong">
							<span>{{item["sumteamscore"]}}</span><template v-if="item['sumteamscore']">pt</template>
						</template>
					</div>
				</div>
				<div class="lf_tit_list_box_con">
					<div class="lf_1">{{langs.dangqianlun}}</div>
					<div :class="'lf_'+(index+2)" v-for="(item,index) in teams">
						<template v-if="danglunkong">
							<span>{{item["teamscore"]}}</span><template v-if="item['teamscore']">pt</template>
						</template>
					</div>
				</div>
			</div>
			<div class="lf_main_box" :style="'height:'+MainMaxHeight+'px'" ref="lf_main_box">
				<div class="lf_score_list" ref="lf_scroll_m_box" :style="'top:'+(-MainTop)+'px'">
					<div ref="lf_scroll_" v-for="(item,index) in (islcd?[1,2]:[1])">
						<div v-for="(pkgroup,pkid) in list">
							<div class="lf_con_list_box" :class="ShowInfoFlag[pkid]?'lf_con_list_box_select':''" @click="ShowInfo(pkid)">
								<div class="lf_con_list_box_tit">
									<div class="lf_1">{{langs.text_di}}{{pkgroup.pk_seq}}{{langs.text_zu}}</div>
									<div :class="'lf_'+(index+2)" v-for="(item,index) in pkgroup.pkgroup_base">
										<div class="lf_con_1">
											<template v-for="(player,plindex) in item.player">
												<template v-if="plindex==1">/</template>
												{{player.player["pl_"+systemlang+"_name"]?player.player["pl_"+systemlang+"_name"]:player.player["pl_cn_name"]}}{{ match_set.mt_amateur=='(A)'&&player.player.pl_type=='AMA'?'(A)':'' }}
											</template>
										</div>
										<div class="lf_con_2">
											<template v-if="item['team_win_score']||item['team_win_score']===0">
												<span>{{item["team_score"]>0?(item["team_to_par"]>0?("+"+item["team_to_par"]):(item["team_to_par"]==0?"E":item["team_to_par"])):""}}/{{item["team_win_score"]}}</span>pt
											</template>
											<template v-else>
												<span>{{item["team_score"]>0?(item["team_to_par"]>0?("+"+item["team_to_par"]):(item["team_to_par"]==0?"E":item["team_to_par"])):""}}</span>
											</template>
										</div>
									</div>
								</div>
							</div>
							<div class="lf_zhankai_box" :style="ShowInfoFlag[pkid]?'height:auto;':'height:0;'">
								<div class="lf_zk_tit_box">
									<div class="lf_zk_1">{{langs.text_ranking1}}</div>
									<div class="lf_zk_2">{{langs.text_player}}</div>
									<div class="lf_zk_3">{{langs.text_Thru}}</div>
									<div class="lf_zk_4" v-for="(i,index) in [1,2,3,4,5,6,7,8,9]">
										<div class="lf_zk_4_1">{{matchhole.hole[i-1].ho_id}}</div>
										<div>{{matchhole.hole[i-1].par}}</div>
									</div>
									<div class="lf_zk_5">
										<div class="lf_zk_4_1">{{langs.text_Totals}}</div>
										<div>{{par[ca_id.toLowerCase()]}}</div>
									</div>
									<div class="lf_zk_6">{{langs.Score}}</div>
									<div class="lf_zk_7">{{langs.defen}}</div>
								</div>
								<div class="lf_zk_con_box" v-for="(item,index) in pkgroup.pkgroup">
									<div class="lf_zk_1" :class="'lf_T'+item.teams.orderid+'_bg'">{{item.rank_code}}{{item.rank}}</div>
									<div class="lf_zk_2">
										<div v-for="(player,plindex) in item.player">
											<span>
												{{ player.player!=undefined?(player.player["pl_"+systemlang+"_name"]?player.player["pl_"+systemlang+"_name"]:player.player["pl_cn_name"]):"" }}{{ match_set.mt_amateur=='(A)'&&player.player.pl_type=='AMA'?'(A)':'' }}{{ player.groupDetail!=undefined&&player.groupDetail.gp_start_hole>9&&player.groupDetail.gp_start_hole<18?'*':'' }}
												<div class="lf_sex_box_b">
													<div class="lf_sex_box" v-if="match_set.tee_show_type==1">{{ player.matchPlayer!=undefined?player.matchPlayer.mp_teename.split("")[0]:"" }}</div>
													<!-- <div class="lf_sex_box">
														<img src="../../assets/images/u7.png" alt="" />
													</div> -->
													<div v-if="((match_set.mt_sex_show_type=='male'||match_set.mt_sex_show_type=='both')&&(player.player!=undefined&&player.player.pl_sex==1))||((match_set.mt_sex_show_type=='female'||match_set.mt_sex_show_type=='both')&&(player.player!=undefined&&player.player.pl_sex==0))" class="lf_sex_box">
														<img v-if="(match_set.mt_sex_show_type=='male'||match_set.mt_sex_show_type=='both')&&(player.player!=undefined&&player.player.pl_sex==1)" class="lf_country_sex_img" src="../../assets/images/u7.png" alt="">
														<img v-if="(match_set.mt_sex_show_type=='female'||match_set.mt_sex_show_type=='both')&&(player.player!=undefined&&player.player.pl_sex==0)" class="lf_country_sex_img" src="../../assets/images/u9.png" alt="">
													</div>
												</div>
											</span>
											<span v-if="item.teams!=undefined">{{ item.teams["team_"+(systemlang=="cn"||systemlang=="tw"?"ch":"en")+"_name"]?item.teams["team_"+(systemlang=="cn"||systemlang=="tw"?"ch":"en")+"_name"]:item.teams["team_ch_name"] }}</span>
										</div>
									</div>
									<template v-for="(player,plindex) in item.player">
										<template v-if="plindex==0">
											<div class="lf_zk_3" :class="player.playerSummary!=undefined&&player.playerSummary.sts=='C'?'lf_F_Lv':''">{{player.playerSummary!=undefined&&player.playerSummary.thru!=0?(player.playerSummary.thru>=9?"F":player.playerSummary.thru):""}}</div>
										</template>
									</template>
									<template v-for="(player,plindex) in item.player">
										<template v-if="plindex==0">
											<div class="lf_zk_4" :class="player.playerScore==undefined||player.playerScore[matchhole.hole[i-1].ho_id-1]==undefined||player.playerScore[matchhole.hole[i-1].ho_id-1].score==''||player.playerScore[matchhole.hole[i-1].ho_id-1].score==99?'':(player.playerScore[matchhole.hole[i-1].ho_id-1].to_par<=-2?lf_score_1:(player.playerScore[matchhole.hole[i-1].ho_id-1].to_par==-1?lf_score_2:(player.playerScore[matchhole.hole[i-1].ho_id-1].to_par==0?lf_score_3:(player.playerScore[matchhole.hole[i-1].ho_id-1].to_par==1?lf_score_4:lf_score_5))))" v-for="(i,index) in [1,2,3,4,5,6,7,8,9]">
												{{player.playerScore!=undefined&&player.playerScore[matchhole.hole[i-1].ho_id-1]!=undefined?(player.playerScore[matchhole.hole[i-1].ho_id-1].score==99?"-":player.playerScore[matchhole.hole[i-1].ho_id-1].score):""}}
											</div>
											<div class="lf_zk_5">
												{{item["team_score"]>0?item["team_score"]:""}}
											</div>
										</template>
									</template>
									<div class="lf_zk_6" :class="item['team_score']>0?(item.team_to_par<0?'lf_score_other_2':(item.team_to_par==0?'lf_score_other_3':'lf_score_other_4')):''">{{item["team_score"]>0?(item["team_to_par"]>0?("+"+item["team_to_par"]):(item["team_to_par"]==0?"E":item["team_to_par"])):""}}</div>
									<div class="lf_zk_7" :class="'lf_T'+item.teams.orderid+'_bg'">{{item["team_win_score"]||item["team_win_score"]===0?item["team_win_score"]:""}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Select from "../../components/select.vue";
	import Foot from "../../components/foot.vue";
	import axios from '../../axios/request';
	import yard9 from '../yard9.vue';
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'
	//自动轮播的配置
	var app1 = {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				MainMaxHeight: '',
				MainTop: 0,
				ShowInfoFlag:[],
				outpar:'',
				inpar:'',
				cpar:'',
				par:[],
				mt_id:'',
				
				ca_id:'OUT',
				ca_num:0,
				showjinji:-1,
				lf_score_1:"lf_score_1",
				lf_score_2:"lf_score_2",
				lf_score_3:"lf_score_3",
				lf_score_4:"lf_score_4",
				lf_score_5:"lf_score_5",
				zongfenkong: false,
				danglunkong: false,
			}
		},
		props: {
			list:Object,
			playoff:Object,
			match:Object,
			match_set:Object,
			matchhole:Object,
			rd_id:String,
			teams:Object,
			islcd:String
		},
		name: 'Home',
		components: {
			yard9,
			navs,
			Btn,
			Select,
			Swiper,
			SwiperSlide,
			Foot,
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			// this.rd_id = this.match_set.rd_id;
			this.mt_id = this.match_set.mt_id;
			for(var k in this.match_set.mt_show_style){
				if(k == 0){
					this.lf_score_1 = this.match_set.mt_show_style[k];
				}else if(k == 1){
					this.lf_score_2 = this.match_set.mt_show_style[k];
				}else if(k == 2){
					this.lf_score_3 = this.match_set.mt_show_style[k];
				}else if(k == 3){
					this.lf_score_4 = this.match_set.mt_show_style[k];
				}else if(k == 4){
					this.lf_score_5 = this.match_set.mt_show_style[k];
				}
			}
			this.changepar();
			this.ca_id = this.matchhole.hole[this.ca_num*9].ho_id==1?"OUT":(this.matchhole.hole[this.ca_num*9].ho_id==10?"IN":"C");
			for(var k in this.list){
				if(this.islcd){
					this.ShowInfoFlag[k] = true;
				}else{
					this.ShowInfoFlag[k] = false;
				}
			}
			for(var k in this.teams){
				if(this.teams[k].sumteamscore!=0){
					this.zongfenkong = true;
				}
				if(this.teams[k].teamscore!=0){
					this.danglunkong = true;
				}
			}
		},
		mounted() {
			var that=this;
			var DomHeight = window.innerHeight;//浏览器窗口大小
			var scrollHT = this.$refs.lf_main_box_top.clientHeight+30;//固定内容高度
			var lf_scroll_m_box = this.$refs.lf_scroll_m_box.clientHeight;//
			var scrollH0 = this.$refs.lf_scroll_[0].clientHeight;
			if(this.islcd){
				if (scrollH0 > this.MainMaxHeight) {
					this.MainMaxHeight = DomHeight - scrollHT;
				}else{
					this.MainMaxHeight=scrollH0;
					document.body.style.height = (scrollHT+scrollH0)+"px";
				}
			}else{
				this.MainMaxHeight='auto';
				document.body.style.height='auto';
			}
			
			console.log('---',this.MainTop,this.MainMaxHeight,lf_scroll_m_box,'---')
			var speed = 60;
			var url = location.href.replace("lcd=1", "lcd=0");
			if (scrollH0 > this.MainMaxHeight&&this.islcd) {
				setInterval(function() {
					if (that.MainTop < scrollH0) {
						that.MainTop++;

					} else {
						//console.log(that.MainTop , (that.MainMaxHeight+lf_scroll_m_box))
						that.MainTop=0;
					}

				}, speed)
			} else {
				setInterval(function() {

				}, 20000)
			}
		},
		watch:{
			rd_id:function(nval,oval){
				this.rd_id = nval;
			},
			list:function(nval,oval){
				this.$nextTick(()=>{
					this.$parent.setscorecolor();
				});
			},
			teams:function(nval,oval){
				for(var k in nval){
					if(nval[k].sumteamscore!=0){
						this.zongfenkong = true;
					}
					if(nval[k].teamscore!=0){
						this.danglunkong = true;
					}
				}
				console.log("this.zongfenkong",this.zongfenkong);
			}
		},
		methods: {
			changepar(){
				var outpar = 0;
				var inpar = 0;
				var cpar = 0;
				for(var k in this.matchhole.hole){
					if(this.matchhole.hole[k].ho_id<=9){
						outpar+=this.matchhole.hole[k].par;
					}else if(this.matchhole.hole[k].ho_id<=18&&this.matchhole.hole[k].ho_id>9){
						inpar+=this.matchhole.hole[k].par;
					}else if(this.matchhole.hole[k].ho_id<=27&&this.matchhole.hole[k].ho_id>18){
						cpar+=this.matchhole.hole[k].par;
					}
				}
				this.par["out"] = outpar;
				this.par["in"] = inpar;
				this.par["c"] = cpar;
			},
			changecaid(ca_id){
				// this.ca_id = ca_id;
				if(ca_id=="OUT"){
					this.ca_num = 0;
				}else if(ca_id=="IN"){
					this.ca_num = 1;
				}else if(ca_id=="C"){
					this.ca_num = 2;
				}
				this.ca_id = this.matchhole.hole[this.ca_num*9].ho_id==1?"OUT":(this.matchhole.hole[this.ca_num*9].ho_id==10?"IN":"C");
			},
			ShowInfo(pkid){
				this.ShowInfoFlag[pkid] = !this.ShowInfoFlag[pkid];
			}
		}
	}
	export default app1;
</script>
<style scoped="scoped">
	.lf_score_box {
		width: 1280px;
		margin: 0 auto;
		height: 100%;
		overflow: hidden;
	}

	.lf_score_list {
		position: relative;
	}

	.lf_score_list_box_swiper {
		width: 100%;
		height: 1000px;
	}

	.lf_1 {
		width: 110px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_2,
	.lf_3,
	.lf_4 {
		width: 390px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 22px;
	}

	.lf_tit_list_box {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
		position: relative;
		z-index: 99;
		
	}

	.lf_tit_list_box_tit {
		display: flex;
		align-items: center;
		height: 60px;
		color: #fff;
	}

	.lf_tit_list_box_tit div {
		border-right: 1px solid #fff;
		border-bottom: 1px solid #fff;
	}

	.lf_tit_list_box_tit .lf_2,
	.lf_tit_list_box_tit .lf_3,
	.lf_tit_list_box_tit .lf_4 {
		font-size: 24px;
		font-weight: bold;
	}

	.lf_tit_list_box_tit .lf_1 {
		font-size: 22px;
		background-color: #3F3939;
	}

	.lf_tit_list_box_con .lf_1 {
		font-size: 22px;
	}

	.lf_tit_list_box_tit .lf_2 {
		background-color: #E5007F;
	}

	.lf_tit_list_box_tit .lf_3 {
		background-color: #A7CD46;
	}

	.lf_tit_list_box_tit .lf_4 {
		background-color: #00AEEB;
	}

	.lf_1 {
		font-weight: bold;
	}

	.lf_tit_list_box_con {
		display: flex;
		align-items: center;
		height: 80px;
		color: #3F3939;
		background-color: #fafafa;
	}

	.lf_tit_list_box_con div {
		border-right: 1px solid #fff;
		border-bottom: 1px solid #fff;
	}

	.lf_tit_list_box_con .lf_2 {
		
		color: #E5007F;
		font-size: 30px;
	}

	.lf_tit_list_box_con .lf_3 {
		color: #A7CD46;
		font-size: 30px;
	}

	.lf_tit_list_box_con .lf_4 {
		color: #00AEEB;
		font-size: 30px;
	}

	.lf_tit_list_box_con .lf_2 span,
	.lf_tit_list_box_con .lf_3 span,
	.lf_tit_list_box_con .lf_4 span {
		font-weight: bold;
	}


	.lf_con_list_box {
		display: flex;
		flex-direction: column;
	}

	.lf_con_list_box_tit {
		display: flex;
		align-items: center;
		height: 60px;
		color: #fff;
		border-bottom: 1px solid #fff;
	}

	.lf_con_list_box_select div {
		font-weight: bold!important;
	}

	.lf_con_list_box_tit .lf_2,
	.lf_con_list_box_tit .lf_3,
	.lf_con_list_box_tit .lf_4 {
		font-size: 22px;
		display: flex;
		align-items: center;
		border-right: 1px solid #fff;
	}

	.lf_con_list_box_tit .lf_1 {
		font-size: 22px;
		background-color: #3F3939;
		border-right: 1px solid #fff;
	}

	.lf_con_list_box_tit .lf_2 {
		
		background-color: #E5007F;
	}

	.lf_con_list_box_tit .lf_3 {
		background-color: #A7CD46;
	}

	.lf_con_list_box_tit .lf_4 {
		background-color: #00AEEB;
	}

	.lf_con_1 {
		width: 270px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_con_2 {
		width: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
	}
	.lf_con_2 span{
		font-weight: bold;
	}
	/* 展开 s */
	.lf_zhankai_box{
		overflow: hidden;
	}
	.lf_zk_tit_box {
		display: flex;
		align-items: center;
		height: 60px;
		color: #3F3939;
		border-bottom: 1px solid #dbdbdb;
		background-color: #F0F0F0;
		font-size: 20px;
	}

	.lf_zk_tit_box div {
		height: 100%;
	}

	.lf_zk_1 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 110px;
	}

	.lf_zk_2 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 290px;
		flex-grow: 1;
	}

	.lf_zk_3 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 85px;
	}

	.lf_zk_4 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		flex-direction: column;
	}

	.lf_zk_4 div {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50%;
		width: 100%;
	}

	.lf_zk_5 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		flex-direction: column;
	}

	.lf_zk_5 div {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50%;
		width: 100%;
	}

	.lf_zk_4_1 {
		background-color: #DBDBDB;
	}

	.lf_zk_6 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 75px;
	}

	.lf_zk_7 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		font-weight: bold;
	}

	.lf_zk_con_box {
		display: flex;
		align-items: center;
		height: 160px;
		color: #000;
		border-bottom: 1px solid #dbdbdb;
	}

	.lf_zk_con_box .lf_zk_1 {
		font-size: 24px;
		height: 100%;
	}

	.lf_zk_con_box .lf_zk_2 {
		font-size: 22px;
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: flex-start;
		padding-left: 10px;
	}

	.lf_zk_con_box .lf_zk_2 div {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		flex-direction: column;
		width: 100%;
		height: 50%;
	}

	.lf_zk_con_box .lf_zk_2 div span {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.lf_zk_con_box .lf_zk_2 div span:nth-last-of-type(1) {
		font-size: 16px;
		color: #55565B;
		margin-bottom: 6px;
	}

	.lf_zk_con_box .lf_zk_2 div .lf_sex_box_b {
		display: flex;
		align-items: center;
		flex-direction: row;
		width: auto;
	}

	.lf_zk_con_box .lf_zk_2 .lf_sex_box {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: bold;
		background-color: rgba(238, 238, 238, 1);
		float: right;
		margin-right: 6px;
		border-radius: 50%;
	}

	.lf_zk_con_box .lf_zk_2 .lf_sex_box img {
		width: 16px;
		height: 16px;
	}

	.lf_zk_con_box .lf_zk_3 {
		font-size: 24px;
		color: #000;
	}

	.lf_zk_con_box .lf_zk_4 {
		font-size: 24px;
		/* color: #000; */
		height: 100%;
	}

	.lf_zk_con_box .lf_zk_5 {
		font-size: 24px;
		color: #000;
		height: 100%;
		font-weight: bold;
	}

	.lf_zk_con_box .lf_zk_6 {
		font-size: 24px;
		color: #000;
		height: 100%;
		background-color: #F0F0F0;
	}

	.lf_zk_con_box .lf_zk_7 {
		font-size: 24px;
		color: #000;
		height: 100%;
		font-weight: bold;
	}

	/* 展开 end */
	.lf_T1_bg {
		background-color: #F280BF;
	}
	
	.lf_T2_bg {
		background-color: #D3E6A3;
	}
	.lf_T3_bg {
		background-color: #80D7F5;
	}
	.lf_F_Lv {
		font-size: 24px;
		color: #027512 !important;
		font-weight: bold;
	}

	@media screen and (max-width: 768px) {}
</style>