import axios from "axios";
import {
    getSystemLang
} from "../lang";


export default function($url,$param){

    let lang = getSystemLang();
    if(lang == 'cn')
    {
        lang = 'zh-cn';
    }
    else if(lang == 'en')
    {
        lang = 'en-us';
    }
    else if(lang == 'tw')
    {
        lang = 'zh-tw';
    }
    else
    {
        lang = 'zh-cn';
    }

    let tparam = [];
    if($param instanceof Object)
    {
        for(let key in $param)
        {
            tparam.push(key+'='+ encodeURIComponent($param[key]));
        }
    }

    

    
    return axios.post($url,$param);
}