<template>
    <div v-loading="loading"
		element-loading-text="拼命加载中"
	    element-loading-spinner="el-icon-loading"
	    element-loading-background="rgba(0, 0, 0, 0.3)"
		style="height: 100%;"
	>
        <navs></navs>
        <Btn v-if="matchmode!='LRYQ'&&matchmode!='LRLQ'" cd="home" undergoing="Home" :gofunction="gofunction"></Btn>
        <Select ref="select" :match="match" :match_set="match_set" :modal="modal" :leader="leader" :matchmode="matchmode=='LRLQ'?'SRSQ':(matchmode=='LRYQ'?'SRLQ':matchmode)"></Select>
        <template v-if="match.mt_id!=undefined">
			<Leader  v-if="matchmode=='leader'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></Leader>
            <!-- 组别成绩 -->
            <Group v-if="matchmode=='group'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></Group>
            <Group9 v-if="matchmode=='group9'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></Group9>
            <!-- 比杆27洞 -->
            <PerBGZG27 v-if="matchmode=='BGS27'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></PerBGZG27>
            <!-- 比杆9洞 -->
            <PerBGZG9 v-if="matchmode=='BGS9'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></PerBGZG9>
            <!-- 比杆18洞 -->
            <PerBGZG18 v-if="matchmode=='BGS'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></PerBGZG18>
            <!-- 贝利亚 -->
            <BLY v-if="matchmode=='NNP'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></BLY>
            <!-- 自报差点 -->
            <NNP_ZBCD v-if="matchmode=='NNP_ZBCD'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></NNP_ZBCD>
            <!-- 自报差点 -->
            <ZBCD v-if="matchmode=='ZBCD'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id"></ZBCD>
            <!-- 六人三球 -->
            <LRYQ v-if="matchmode=='LRYQ'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id" :teams="teams" :islcd="false"></LRYQ>
            <!-- 六人六球 -->
            <LRLQ v-if="matchmode=='LRLQ'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id" :teams="teams" :islcd="false"></LRLQ>
        </template>
		<Foot></Foot>
    </div>
</template>

<script>
    // @ is an alias to /src
    import navs from "../components/nav.vue";
    import Btn from "../components/Btn.vue";
	import Select from "../components/select.vue";
	import PerBGZG9 from "../components/scoreP/BGZG9.vue";
	import PerBGZG18 from "../components/scoreP/BGZG18.vue";
	import PerBGZG27 from "../components/scoreP/BGZG27.vue";
	import Group from "../components/scoreP/Group.vue";
	import Group9 from "../components/scoreP/Group9.vue";
	import BLY from "../components/scoreP/BLY.vue";
	import ZBCD from "../components/scoreP/ZBCD.vue";
	import Foot from "../components/foot.vue";
    import NNP_ZBCD from "../components/scoreP/NNP_ZBCD.vue";
	import Leader from "../components/scoreP/leader.vue";
    import axios from '../axios/request';
	import LRYQ from "../components/Tpga/LRYQ.vue";
	import LRLQ from "../components/Tpga/LRLQ.vue";
    import {
        getSystemLang,
        getLangAll
    } from "../lang";
    //这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
    import {
        Swiper,
        SwiperSlide
    } from 'swiper/vue/swiper-vue';

    import {
        Autoplay,Pagination
    } from 'swiper';
    import 'swiper/swiper-bundle.css';
    import 'swiper/swiper.min.css';
    //这里就是更新后的路径，以前是import 'swiper/css'
    //自动轮播的配置
    var app1 = {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
                list:[],
                match:[],
                match_set:[],
                matchhole:[],
                playoff:[],
                bygroup:"",
                listmode:"",
                matchmode:"",
                loading:false,
                rd_id:"",
                leader:"",
                teams:[]
            }
        },
        props: {

        },
        name: 'Home',
        components: {
            navs,
            Btn,
			Select,
			PerBGZG9,
			PerBGZG18,
			PerBGZG27,
			Group,
			Group9,
            NNP_ZBCD,
			ZBCD,
			BLY,
            Swiper,
            SwiperSlide,
			Foot,
			Leader,
            LRYQ,
            LRLQ
        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
            var params = this.$route.query;
            var mt_id = this.getQueryVariable("MatchID")===false?31934:this.getQueryVariable("MatchID");
            var rd_id = params.RoundID==undefined?"":params.RoundID;
            var list = params.list==undefined?"":params.list;
            var bygroup = params.bygroup==undefined?"":params.bygroup;
            var lang = params.lang==undefined?"":params.lang;
            var g = params.g==undefined?"":params.g;
            var modal = params.modal==undefined?"":params.modal;
            var leader = params.leader==undefined?"":params.leader;
            if(bygroup == 1){
                leader = "";
            }
            this.modal = modal;
            this.listmode = list;
            this.bygroup=bygroup;
            this.leader = leader;
            this.loading = true;
            if(this.listmode == "pk"){
                axios('score/index.json',{mt_id:mt_id,rd_id:rd_id,list:list,bygroup:bygroup,lang:lang,gid:g,modal:modal,leader:leader})
                .then((res)=>{
                    var data = res.data.data;
                    this.list = data.list;
                    this.match = data.match;
                    this.match_set = data.match_set;
                    this.matchhole = data.matchhole;
                    this.teams = data.teams;
                    if(rd_id == ''){
                        this.rd_id = data.match_set.rd_id;
                    }else{
                        this.rd_id = rd_id;
                    }
                    if(this.match_set.pk[this.rd_id-1].type=="SRLQ_BDS"&&this.match_set.pk[this.rd_id-1].scoretype==1){
                        this.matchmode="LRYQ";
                    }else if(this.match_set.pk[this.rd_id-1].type=="SRSQ_BDS"&&this.match_set.pk[this.rd_id-1].scoretype==1){
                        this.matchmode="LRLQ";
                    }
                    // console.log(this.matchmode)// = "BGS9";
                    this.loading = false;
                });
                setInterval(() => {
                    axios('score/index.json',{mt_id:mt_id,rd_id:rd_id,list:list,bygroup:bygroup,lang:lang,gid:g,modal:modal,leader:leader})
                    .then((res)=>{
                        var data = res.data.data;
                        this.list = data.list;
                        this.match = data.match;
                        this.match_set = data.match_set;
                        this.matchhole = data.matchhole;
                        this.teams = data.teams;
                    });
                }, 300000);
            }else{
                axios('match/rankbgs.json',{mt_id:mt_id,rd_id:rd_id,list:list,bygroup:bygroup,lang:lang,gid:g,modal:modal,leader:leader})
                .then((res)=>{
                    var data = res.data.data;
                    this.list = data.list;
                    this.match = data.match;
                    this.match_set = data.match_set;
                    this.matchhole = data.matchhole;
                    this.playoff = data.playoff;
                    if(data.playoff.length==0){
                        this.match.playoffhole = 0;
                    }
                    if(rd_id == ''){
                        this.rd_id = data.match_set.rd_id;
                    }else{
                        this.rd_id = rd_id;
                    }
                    if(this.bygroup==1){
                        if(this.matchhole.hole.length==9){
                            this.matchmode = "group9";
                        }else{
                            this.matchmode = "group";
                        }
                    }else if(this.leader==1){
                        this.matchmode = "leader";
                    }else if(this.modal!='NetScore'&&this.matchhole.hole.length==27){
                        this.matchmode = "BGS27";
                    }else if(this.modal!='NetScore'&&this.matchhole.hole.length==9){
                        this.matchmode = "BGS9";
                    }else if(this.modal=='NetScore'&&(this.listmode=='NNP'||this.match_set.mt_scoring_mode=='NNP'||this.match_set.mt_scoring_mode=='NNP_ZBCD')){
                        if(this.match_set.mt_scoring_mode=='NNP_ZBCD'){
                            this.matchmode = "NNP_ZBCD";
                            this.listmode='NNP_ZBCD'
                        }else{
                            this.matchmode = "NNP";
                        }
                    }else if(this.modal=='NetScore'&&(this.listmode=='ZBCD'||this.match_set.mt_scoring_mode=='ZBCD')){
                        this.matchmode = "ZBCD";
                    }else if(this.modal!='NetScore'){
                        this.matchmode = "BGS";
                    }
                    // this.matchmode="leader";
                    // console.log(this.matchmode)// = "BGS9";
                    this.loading = false;
                });
                setInterval(() => {
                    this.getData(mt_id,rd_id,list,bygroup,lang,g,modal,leader);
                }, 300000);
            }
            
        },
        setup() {
            const onSwiper = (swiper) => {
                console.log(swiper);
            };
            const onSlideChange = (e) => {
                console.log('slide change123123123',e.activeIndex);
            };
            const autoplayOptions = {
                delay: 5000,
                disableOnInteraction: false,
                loop: false,
                pauseOnMouseEnter: true,
                reverseDirection: true
            };
            return {
                onSwiper,
                onSlideChange,
                autoplayOptions,
                modules: [Pagination,Autoplay],
            };
        },
        methods: {
			gofunction(e){
                console.log(this.match);
                this.$refs.select.getMatchList(e+'',this.match.club_id,this.match.tour_id,'go');
            },
            getQueryVariable(variable)
			{
				var query = window.location.search.substring(1);
				var vars = query.split("&");
                var returnval = "";
				for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    if(pair[0] == variable){returnval = pair[1];}
				}
                if(returnval!=""){
                    return returnval;
                }
				return(false);
			},
            getData(mt_id,rd_id,list,bygroup,lang,g,modal,leader){
                axios('match/rankbgs.json',{mt_id:mt_id,rd_id:rd_id,list:list,bygroup:bygroup,lang:lang,gid:g,modal:modal,leader:leader})
                .then((res)=>{
                    var data = res.data.data;
                    this.list = data.list;
                    this.playoff = data.playoff;
                    this.match_set = data.match_set;
                    if(data.playoff.length==0){
                        this.match.playoffhole = 0;
                    }
                });
            },
            setscorecolor(){
                for (let index = 0; index < document.getElementsByClassName("lf_score_9").length; index++) {
                    document.getElementsByClassName("lf_score_9")[index].style.backgroundColor = "#FFFFFF00";
                    document.getElementsByClassName("lf_score_9")[index].style.color = "#000000";
                }
                var colorstyle = this.match_set.colorstyle;
                for(var k in colorstyle){
                    for (let index = 0; index < document.getElementsByClassName("lf_score_"+colorstyle[k].id).length; index++) {
                        document.getElementsByClassName("lf_score_"+colorstyle[k].id)[index].style.backgroundColor = colorstyle[k].background;
                        document.getElementsByClassName("lf_score_"+colorstyle[k].id)[index].style.color = colorstyle[k].color;
                    }
                }
            }
        }
    }
    export default app1;
</script>
<style scoped="scoped">
    @media screen and (max-width: 768px) {
        
    }
</style>
