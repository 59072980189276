<template>
	<div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.3)" style="height: 100%;">
		<navs></navs>
		<div class="lf_match_top_box" v-if="match!=undefined">
			<div class="lf_playerscore_1">
				<div class="lf_diyiming">{{langs["Pos."]}} {{typeof(player['su_rank'])=='string'?player['su_rank'].replace('T',''):player['su_rank']}} {{langs["ming"]}}</div>
				<img v-if="match.mt_country_show_type=='1'" class="lf_playerscore_country_img"
                :src="'http://overview.wifigolf.com/images/flags/changurl.php?mt_id='+mt_id+'&content=64/'+player['pl_country']+'.png'" alt="">
				<div class="lf_country_text" v-else-if="match.mt_country_show_type=='2'">
					{{player.pl_country}}
				</div>
				{{player.pl_cn_name}}
                <template v-if="match.mt_amateur=='1'">
                    {{player.pl_type}}
                </template>
				<div
					style="border-radius:50%;font-size:26px;width:40px;height:40px;line-height:40px;text-align:center;margin-left:20px;margin-top: 2px;background: #ccc;" v-if="match.tee_show_type!='0'">
					{{player['mp_tee']}}
				</div>
				<template v-if="match.mt_sex_show_type!='0'">
					<img v-if="(match.mt_sex_show_type=='male' || match.mt_sex_show_type=='both') && player['pl_sex']==1" class="lf_playerscore_sex_img" src="../../src/assets/images/u7.png" alt="">
					<img v-if="(match.mt_sex_show_type=='female' || match.mt_sex_show_type=='both') && player['pl_sex']==0" class="lf_playerscore_sex_img" src="../../src/assets/images/u9.png" alt="">
				</template>
				<span v-if="player.su_to_par>0" style="background:#025287">+{{player['su_to_par']}}</span>
				<span v-else-if="player.su_to_par==0" style="background:#53565B">E</span>
				<span v-else style="background:#B72A47">{{player['su_to_par']}}</span>
			</div>
		</div>
		<div class="lf_pl_score_box_" v-if="match!=undefined">
			<div class="lf_pl_score_box_tit">
				<span>成绩单 Scorecard</span>
				- {{match['mt_cn_name']}}
			</div>
            <template v-if="true" v-for="(roundata,rd_id) in holescoreround">
                <div class="lf_round_C_T1">
                    <div class="lf_round_C_T1_R_t">
                        <img v-if="rd_id==1" src="../../src/assets/images/R1.png" alt="" />
                        <img v-if="rd_id==2" src="../../src/assets/images/R2.png" alt="" />
                        <img v-if="rd_id==3" src="../../src/assets/images/R3.png" alt="" />
                        <img v-if="rd_id==4" src="../../src/assets/images/R4.png" alt="" />
                        <img v-if="rd_id==5" src="../../src/assets/images/R5.png" alt="" />
                    </div>
                    <div class="lf_per_score_box">
                        <div class="lf_per_score_box_tit">
                            <div class="lf_per_score_1">HOLE</div>
                            <div :class="(index+1)%3==0&&index!=8?'lf_per_score_2 lf_per_score_r':'lf_per_score_2'"
                                v-for="(item,index) in [roundata[0],roundata[1],roundata[2],roundata[3],roundata[4],roundata[5],roundata[6],roundata[7],roundata[8]]">{{item.sc_ho_id}}</div>
                            <div class="lf_per_score_out_in">OUT</div>
                            <div :class="(index+1)%3==0&&index!=8?'lf_per_score_2 lf_per_score_r':'lf_per_score_2'"
                                v-for="(item,index) in [roundata[9],roundata[10],roundata[11],roundata[12],roundata[13],roundata[14],roundata[15],roundata[16],roundata[17]]">{{item.sc_ho_id}}</div>
                            <div class="lf_per_score_out_in">IN</div>
                            <div class="lf_per_score_tot">TOT</div>
                        </div>
                        <div class="lf_per_score_box_par">
                            <div class="lf_per_score_1">标准杆</div>
                            <div :class="(index+1)%3==0&&index!=8?'lf_per_score_2 lf_per_score_r':'lf_per_score_2'"
                                v-for="(item,index) in [roundata[0],roundata[1],roundata[2],roundata[3],roundata[4],roundata[5],roundata[6],roundata[7],roundata[8]]">{{item.sc_par}}</div>
                            <div class="lf_per_score_out_in">{{holeoutround[rd_id].sc_par}}</div>
                            <div :class="(index+1)%3==0&&index!=8?'lf_per_score_2 lf_per_score_r':'lf_per_score_2'"
                                v-for="(item,index) in [roundata[9],roundata[10],roundata[11],roundata[12],roundata[13],roundata[14],roundata[15],roundata[16],roundata[17]]">{{item.sc_par}}</div>
                            <div class="lf_per_score_out_in">{{holeinround[rd_id].sc_par}}</div>
                            <div class="lf_per_score_tot">{{holeallround[rd_id].sc_par}}</div>
                        </div>
                        <div class="lf_per_score_box_score">
                            <div class="lf_per_score_1">成绩</div>
                            <div :class="(index+1)%3==0&&index!=8?'lf_per_score_2 lf_per_score_r':'lf_per_score_2'"
                                v-for="(item,index) in [roundata[0],roundata[1],roundata[2],roundata[3],roundata[4],roundata[5],roundata[6],roundata[7],roundata[8]]">
                                {{item.sc_score}}
                            </div>
                            <div class="lf_per_score_out_in">{{holeoutround[rd_id].sc_score}}</div>
                            <div :class="(index+1)%3==0&&index!=8?'lf_per_score_2 lf_per_score_r':'lf_per_score_2'"
                                v-for="(item,index) in [roundata[9],roundata[10],roundata[11],roundata[12],roundata[13],roundata[14],roundata[15],roundata[16],roundata[17]]">{{item.sc_score}}</div>
                            <div class="lf_per_score_out_in">{{holeinround[rd_id].sc_score}}</div>
                            <div class="lf_per_score_tot">{{holeallround[rd_id].sc_score}}</div>
                        </div>
                    </div>
                    <div id="main" class="chart" :ref="chart" style="width: 1100px;height:220px;margin: 0 auto;"></div>
                    <div class="lf_pie_box">
                        <div class="lf_pie_">
                            <div id="main1" class="chart1" :ref="chart1" style="width: 520px;height:220px;margin:0;"></div>
                            <div class="lf_pross_box">
                                <div style="width:10%;"></div>
                                <div style="width:10%;"></div>
                                <div style="width:10%;"></div>
                                <div style="width:10%;"></div>
                                <div style="width:10%;"></div>
                                <div style="width:50%;"></div>
                            </div>
                            <div class="lf_pross_box1">
                                <div>{{ scorenumround[rd_id].eagle }}{{ langs["ge"] }}</div>
                                <div>{{ scorenumround[rd_id].birdie }}{{ langs["ge"] }}</div>
                                <div>{{ scorenumround[rd_id].par }}{{ langs["ge"] }}</div>
                                <div>{{ scorenumround[rd_id].borgey }}{{ langs["ge"] }}</div>
                                <div>{{ scorenumround[rd_id].doubleborgey }}{{ langs["ge"] }}</div>
                                <div>{{ scorenumround[rd_id].other }}{{ langs["ge"] }}</div>
                            </div>
                        </div>
                        <div class="lf_scorecc_box">
                            <div class="lf_scorecc_box_1">
                                <div>3杆洞成绩</div>
                                <div>4杆洞成绩</div>
                                <div>5杆洞成绩</div>
                            </div>
                            <div class="lf_scorecc_box_2">
                                <div><span>{{scorenumround[rd_id]["3gan"]}}</span><span>({{allscorenumround[rd_id]["3gan"]}})</span></div>
                                <div><span>{{scorenumround[rd_id]["4gan"]}}</span><span>({{allscorenumround[rd_id]["4gan"]}})</span></div>
                                <div><span>{{scorenumround[rd_id]["5gan"]}}</span><span>({{allscorenumround[rd_id]["5gan"]}})</span></div>
                            </div>
                            <div class="lf_scorecc_box_1">
                                <div>前九洞成绩</div>
                                <div>后九洞成绩</div>
                                <div>总成绩</div>
                            </div>
                            <div class="lf_scorecc_box_2" style="margin-bottom: 0.35rem;">
                                <div><span>{{scorenumround[rd_id]["out"]}}</span><span>({{allscorenumround[rd_id]["out"]}})</span></div>
                                <div><span>{{scorenumround[rd_id]["in"]}}</span><span>({{allscorenumround[rd_id]["in"]}})</span></div>
                                <div><span>{{scorenumround[rd_id]["total"]}}</span><span>({{allscorenumround[rd_id]["total"]}})</span></div>
                            </div>
                            <div class="lf_tips_">*括号里为排名第一的球员成绩</div>
                        </div>
                    </div>
                </div>
            </template>
		</div>
		<div class="lf_round_C_T1_t" v-if="scorenumround!=undefined" style="color:black;">
			<div class="lf_round_C_T1_t_tit">统计数据</div>
			<div class="lf_stats_content">
				<div class="lf_stats_tit_t">
					<div>各洞表现</div>
					<div v-for="rd_id in roundarr" >R{{rd_id}}</div>
					<div>整场比赛</div>
				</div>
				<div class="lf_stats_box_b">
					<div>Par3s</div>
					<div v-for="rd_id in roundarr" >{{scorenumround[rd_id]==undefined?'':scorenumround[rd_id]["3gan"]}}</div>
					<div>{{scorenumround[1]==undefined?'':scorenumround[1].match3ganscore}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>Par4s</div>
					<div v-for="rd_id in roundarr" >{{scorenumround[rd_id]==undefined?'':scorenumround[rd_id]["4gan"]}}</div>
					<div>{{scorenumround[1]==undefined?'':scorenumround[1].match4ganscore}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>Par5s</div>
					<div v-for="rd_id in roundarr" >{{scorenumround[rd_id]==undefined?'':scorenumround[rd_id]["5gan"]}}</div>
					<div>{{scorenumround[1]==undefined?'':scorenumround[1].match5ganscore}}</div>
				</div>
			</div>
			<div class="lf_stats_content">
				<div class="lf_stats_tit_t">
					<div>成绩统计</div>
					<div v-for="rd_id in roundarr" >R{{rd_id}}</div>
					<div>整场比赛</div>
				</div>
				<div class="lf_stats_box_b">
					<div>Eagle or Betters</div>
					<div v-for="rd_id in roundarr" >{{scorenumround[rd_id]==undefined?'':scorenumround[rd_id]["eagle"]}}</div>
					<div>{{scorenumround[1]==undefined?'':scorenumround[1].matcheagle}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>Birdies</div>
					<div v-for="rd_id in roundarr" >{{scorenumround[rd_id]==undefined?'':scorenumround[rd_id]["birdie"]}}</div>
					<div>{{scorenumround[1]==undefined?'':scorenumround[1].matchbirdie}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>Pars</div>
					<div v-for="rd_id in roundarr" >{{scorenumround[rd_id]==undefined?'':scorenumround[rd_id]["par"]}}</div>
					<div>{{scorenumround[1]==undefined?'':scorenumround[1].matchpar}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>Bogeys</div>
					<div v-for="rd_id in roundarr" >{{scorenumround[rd_id]==undefined?'':scorenumround[rd_id]["borgey"]}}</div>
					<div>{{scorenumround[1]==undefined?'':scorenumround[1].matchborgey}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>D.bogeys+</div>
					<div v-for="rd_id in roundarr" >{{scorenumround[rd_id]==undefined?'':(scorenumround[rd_id]["doubleborgey"] + scorenumround[rd_id]["other"])}}</div>
					<div>{{scorenumround[1]==undefined?'':(scorenumround[1]["matchdoubleborgey"] + scorenumround[1]["matchother"])}}</div>
				</div>
			</div>
			<div class="lf_stats_content" v-if="statsround!=undefined">
				<div class="lf_stats_tit_t">
					<div>技术统计</div>
					<div v-for="rd_id in roundarr" >R{{rd_id}}</div>
					<div>整场比赛</div>
				</div>
				<div class="lf_stats_box_b">
					<div>开球上球道率</div>
					<div v-for="rd_id in roundarr" >{{ statsround[rd_id]==undefined?'':statsround[rd_id].str_driving_distance }}</div>
					<div>{{matchstats.str_driving_distance}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>GIR</div>
					<div v-for="rd_id in roundarr" >{{ statsround[rd_id]==undefined?'':statsround[rd_id].str_gir }}</div>
					<div>{{matchstats.str_gir}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>平均推杆数</div>
					<div v-for="rd_id in roundarr" >{{ statsround[rd_id]==undefined?'':statsround[rd_id].str_putts_pround }}</div>
					<div>{{matchstats.str_putts_pround}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>GIR平均推杆数</div>
					<div v-for="rd_id in roundarr" >{{ statsround[rd_id]==undefined?'':statsround[rd_id].str_putts_pgir }}</div>
					<div>{{matchstats.str_putts_pgir}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>平均杆数</div>
					<div v-for="rd_id in roundarr" >{{ statsround[rd_id]==undefined?'':statsround[rd_id].str_stroke }}</div>
					<div>{{matchstats.str_stroke}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>救球率</div>
					<div v-for="rd_id in roundarr" >{{ statsround[rd_id]==undefined?'':statsround[rd_id].str_scramble }}</div>
					<div>{{matchstats.str_scramble}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>沙坑救球率</div>
					<div v-for="rd_id in roundarr" >{{ statsround[rd_id]==undefined?'':statsround[rd_id].str_sand_saves }}</div>
					<div>{{matchstats.str_sand_saves}}</div>
				</div>
				<div class="lf_stats_box_b">
					<div>开球平均距离</div>
					<div v-for="rd_id in roundarr" >{{ statsround[rd_id]==undefined?'':statsround[rd_id].str_driving_distance }}</div>
					<div>{{matchstats.str_driving_distance}}</div>
				</div>
			</div>
		</div>
		<Foot></Foot>
	</div>
</template>

<script>
	// @ is an alias to /src
	import navs from "../components/nav.vue";
	import Foot from "../components/foot.vue";
	import axios from '../axios/request';
	import * as echarts from 'echarts';
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'
	//自动轮播的配置
	var app1 = {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				piedata: [[{
					'name': '',
					'value': 0
				}]], //饼状图
                chart:"chart",
                chart1:"chart1",
                match:[],
                player:[],
                countscorenum:[],
                holeinround:[],
                holeoutround:[],
                holeallround:[],
				roundarr:[1,2,3,4,5],
				scorenumround:[],
				allscorenumround:[]
			}
		},
		props: {

		},
		name: 'Home',
		components: {
			navs,
			Swiper,
			SwiperSlide,
			Foot,
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
            let geturl = window.location.href 
            let getqyinfo = geturl.split('?')[1]   
            let getqys = new URLSearchParams('?'+getqyinfo)
            this.mt_id = getqys.get('MatchID');
            this.pl_id = getqys.get('Pl_id');
            setTimeout(function(){
                this.mt_id = getqys.get('MatchID');
                this.pl_id = getqys.get('Pl_id');
            },300)
            this.getDataLoading(this.mt_id,this.pl_id);
		},
		mounted() {
			
			// for(let item in data.scorenum){
			// 	if(item=='birdie'){
			// 		this.piedata[0]={'name':'小鸟',value:data.scorenum[item]}
			// 		this.countscorenum+=data.scorenum[item];
			// 	}else if(item=='borgey'){
			// 		this.piedata[1]={'name':'柏忌',value:data.scorenum[item]}
			// 		this.countscorenum+=data.scorenum[item];
			// 	}else if(item=='par'){
			// 		this.piedata[2]={'name':'帕',value:data.scorenum[item]}
			// 		this.countscorenum+=data.scorenum[item];
			// 	}else if(item=='doubleborgey'){
			// 		this.piedata[3]={'name':'双柏忌',value:data.scorenum[item]}
			// 		this.countscorenum+=data.scorenum[item];
			// 	}else if(item=='eagle'){
			// 		this.piedata[4]={'name':'老鹰',value:data.scorenum[item]}
			// 		this.countscorenum+=data.scorenum[item];
			// 	}else if(item=='other'){
			// 		this.piedata[5]={'name':'其他',value:data.scorenum[item]}
			// 		this.countscorenum+=data.scorenum[item];
			// 	}
			// }

            
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = (e) => {
				console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: false,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
			};
		},
		methods: {
			initCharts() {
                for(var i=0;i<this.$refs.chart.length;i++){
                    let myChart = echarts.init(this.$refs.chart[i]);//this.$refs.chart);
                    myChart.setOption({
                        title: {
                            text: '',
                            left: 'center',
                            textStyle: {
                                color: '#025287',
                                lineHeight: '24',
                                fontSize: '14'
                            },
                            padding: [20, 0, 0, 0]
                        },
                        grid: {
                            bottom: '22%'
                        },
                        xAxis: {
                            type: 'category',
                            data: this.holescorechangeHole[i+1],
                            axisTick: {
                                show: true, // 是否显示坐标轴刻度。
                                interval: 0, // 坐标轴刻度的显示间隔，在类目轴中有效。不设置时默认同 axisLabel.interval 一样。设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推。
                                inside: true, // 默认值false。true 表示坐标轴刻度朝内，false 表示坐标轴刻度朝外
                                alignWithLabel: true,
                            },
                            axisLabel: {
                                show: true, // 是否显示坐标轴刻度标签。
                                interval: 1, // 坐标轴刻度标签的显示间隔，在类目轴中有效。设置成 0 强制显示所有标签，如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推
                                inside: true, // 默认值false。true 表示坐标轴刻度标签朝内，false 表示坐标轴刻度标签朝外
                                rotate: 0, // 刻度标签旋转的角度，旋转的角度从 -90 度到 90 度
                                margin: 20, // 刻度标签与轴线之间的距离
                                color: '#999', // 刻度标签文字的颜色。不设置就默认取 axisLine.lineStyle.color，即与轴线颜色一样
                            },
                        },
                        yAxis: {
                            inverse: true,
                            type: 'value',
                            axisLabel: {
                                color: '#999', // 刻度标签文字的颜色。不设置就默认取 axisLine.lineStyle.color，即与轴线颜色一样
                            },
                            minInterval: 1,
                        },
                        series: [{
                            data: this.holescorechangePar[i+1],
                            type: 'line',
                            smooth: false, //折线直角
                            lineStyle: {
                                color: '#025287',
                                width: 2,
                                type: ''
                            },
                        }]
                    })
                }
			},
			initCharts1() {
                for(var i=0;i<this.$refs.chart1.length;i++){
                    let myChart1 = echarts.init(this.$refs.chart1[i]);
                    myChart1.setOption({
                        title: {
                            text: '',
                            left: 'left',
                            textStyle: {
                                color: '#025287',
                                lineHeight: '24',
                                fontSize: '14'
                            },
                            padding: [15, 0, 0, 0]
                        },
                        grid: {
                            bottom: '82%'
                        },
                        tooltip: {
                            trigger: 'item22'
                        },
                        legend: {
                            orient: 'vertical',
                            x: 'left',
                            top: '8%',
                            itemWidth: 30, // 每个数据项的宽度
                            itemHeight: 20, // 每个数据项的高度
                            padding: 10, // 数据项之间的间距
                            textStyle: {
                                fontSize: 20, // 设置字体大小
                                color: '#333'
                            }
                        },

                        color: 
						['#E3B102','#B72A47','#53565B','#025287','#5808C3','#D7D7D7'],
                        series: [{
                            name: '',
                            type: 'pie',
                            radius: ['50%', '90%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 30,
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.piedata[i+1],
                            center: ["70%", "50%"]
                        }]
                    })
                }
			},
            getDataLoading: function(mt_id,pl_id) {
                var mt_id=mt_id;
                var pl_id=pl_id;
                var lang=this.systemlang
                this.showloading=true;
                this.show1=true;
                axios('match/playerround.json',{mt_id:mt_id,lang:lang,pl_id:pl_id})
                .then((response) => {
                    let data = response.data.data;
                    console.log(data);
                    this.player=data.player;
                    this.match=data.match;
                    this.roundsummary=data.roundsummary;
                    this.roundtotal=data.roundtotal;
                    this.holescoreround=data.holescoreround;
                    this.holescorechangeround=data.holescorechangeround;
                    this.holeinround=data.holeinround;
                    this.holeoutround=data.holeoutround;
                    this.holeallround=data.holeallround;
                    this.scorenumround=data.scorenumround
                    this.allscorenumround=data.allscorenumround
                    this.playerscoreMax=data.playerscoreMax
                    this.statsround=data.stats
                    this.matchstats=data.matchstats ;
                    this.show1=false;
					var roundarr = [];
					for(var rd_id=1;rd_id<=data.match.mt_round_num;rd_id++){
						roundarr.push(rd_id);
					}
					this.roundarr = roundarr;
                    for(let rd_id in data.scorenumround){
                        for(let item in data.scorenumround[rd_id]){
                            if(this.piedata[rd_id] == undefined){
                                this.piedata[rd_id] = []
                            }
                            if(this.countscorenum[rd_id] == undefined){
                                this.countscorenum[rd_id] = 0
                            }
                            if(item=='birdie'){
                                this.piedata[rd_id][1]={'name':this.langs["Birdie"],value:data.scorenumround[rd_id][item]}
                                this.countscorenum[rd_id]+=data.scorenumround[rd_id][item];
                            }else if(item=='borgey'){
                                this.piedata[rd_id][3]={'name':this.langs["Bogey"],value:data.scorenumround[rd_id][item]}
                                this.countscorenum[rd_id]+=data.scorenumround[rd_id][item];
                            }else if(item=='par'){
                                this.piedata[rd_id][2]={'name':this.langs["Par"],value:data.scorenumround[rd_id][item]}
                                this.countscorenum[rd_id]+=data.scorenumround[rd_id][item];
                            }else if(item=='doubleborgey'){
                                this.piedata[rd_id][4]={'name':this.langs["D.Bogey"],value:data.scorenumround[rd_id][item]}
                                this.countscorenum[rd_id]+=data.scorenumround[rd_id][item];
                            }else if(item=='eagle'){
                                this.piedata[rd_id][0]={'name':this.langs["Eagle"],value:data.scorenumround[rd_id][item]}
                                this.countscorenum[rd_id]+=data.scorenumround[rd_id][item];
                            }else if(item=='other'){
                                this.piedata[rd_id][5]={'name':this.langs["Other"],value:data.scorenumround[rd_id][item]}
                                this.countscorenum[rd_id]+=data.scorenumround[rd_id][item];
                            }
                        }
                    }
					console.log("piedata",this.piedata);
                    this.holescorePar = [];
                    for(let rd_id in data.holescoreround){
                        for(let item of data.holescoreround[rd_id]){
                            if(this.holescorePar[rd_id] == undefined){
                                this.holescorePar[rd_id] = []
                            }
                            this.holescorePar[rd_id].push(item.sc_to_par)
                        }
                    }
                    this.holescorechangeHole=[];
                    this.holescorechangePar=[];
                    for(let rd_id in data.holescorechangeround){
                        for(let item of data.holescorechangeround[rd_id]){
                            if(this.holescorechangeHole[rd_id] == undefined){
                                this.holescorechangeHole[rd_id] = []
                            }
                            if(item==data.holescorechangeround[rd_id][0]){
                                this.holescorechangeHole[rd_id].push(this.langs["StartHole"])
                            }else{
                                this.holescorechangeHole[rd_id].push(item.sc_ho_id)
                            }
                            if(this.holescorechangePar[rd_id] == undefined){
                                this.holescorechangePar[rd_id] = []
                            }
                            this.holescorechangePar[rd_id].push(item.sc_to_par)
                        }
                    }
                    this.playername=data.player.pl_cn_name;
                    this.$nextTick(()=>{
                        this.initCharts();
			            this.initCharts1();
                    });
                });
            },
		}
	}
	export default app1;
</script>
<style scoped="scoped">
	.lf_match_top_box {
		width: 1280px;
		margin: 0 auto;
	}

	.lf_playerscore_1 {
		width: 1200px;
		margin: 0 auto;
		height: 94px;
		display: flex;
		align-items: center;
		font-size: 38px;
		font-weight: bold;
		color: #333;
		position: relative;
	}

	.lf_diyiming {
		height: 50px;
		background: inherit;
		background-color: rgba(255, 255, 255, 0);
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 38px;
		color: #333;
		margin-right: 50px;
	}

	.lf_playerscore_country_img {
		width: 40px;
		height: 30px;
		margin-right: 20px;
	}

	.lf_playerscore_sex_img {
		width: 40px;
		height: 40px;
		margin: 0 10px;
	}

	.lf_playerscore_1 span {
		width: 120px;
		height: 60px;
		background: inherit;
		background-color: rgba(183, 42, 71, 1);
		border: none;
		border-radius: 10px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 38px;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 0;
	}

	.lf_pl_score_box_ {
		width: 1200px;
		background: inherit;
		background-color: rgba(239, 243, 246, 1);
		border: none;
		border-radius: 10px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		margin: 0 auto 30px;
		padding-bottom: 24px;
	}

	.lf_pl_score_box_tit {
		font-style: normal;
		font-size: 30px;
		color: #0E1B4D;
		height: 70px;
		display: flex;
		align-items: center;
		width: 1120px;
		margin: 0 auto;
	}

	.lf_pl_score_box_tit span {
		font-weight: bold;
	}

	.lf_round_C_T1 {
		width: 1120px;
		background-color: #fff;
		margin: 0 auto;
		border-radius: 10px;
	}

	.lf_round_C_T1_R_t {
		width: 1120px;
		height: 55px;
		background: linear-gradient(87.6380321072723deg, rgba(0, 102, 204, 1) 7%, rgba(198, 206, 249, 1) 95%);
		border: none;
		border-radius: 10px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		display: flex;
		align-items: flex-end;
	}

	.lf_round_C_T1_R_t img {
		height: 45px;
		width: auto;
		margin-left: 30px;
	}

	.lf_per_score_box {
		width: 1100px;
		margin: 10px auto;
		border-bottom: 1px solid #DBDBDB;
	}

	.lf_per_score_box_tit {
		height: 56px;
		background-color: #0E1B4D;
		display: flex;
		align-items: center;
		font-size: 20px;
	}

	.lf_per_score_box_par {
		height: 56px;
		background-color: #95A6EC;
		display: flex;
		align-items: center;
		font-size: 20px;
	}

	.lf_per_score_box_score {
		height: 56px;
		background-color: #fff;
		display: flex;
		align-items: center;
		font-size: 24px;
	}

	.lf_per_score_box_tit div,
	.lf_per_score_box_par div,
	.lf_per_score_box_score div {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.lf_per_score_box_tit .lf_per_score_r,
	.lf_per_score_box_par .lf_per_score_r {
		border-right: 1px solid #fff;
	}

	.lf_per_score_box_score .lf_per_score_r {
		border-right: 1px solid #F2F2F2;
	}

	.lf_per_score_box_tit .lf_per_score_1,
	.lf_per_score_box_par .lf_per_score_1,
	.lf_per_score_box_score .lf_per_score_1 {
		width: 100px;
	}

	.lf_per_score_box_tit .lf_per_score_2,
	.lf_per_score_box_par .lf_per_score_2,
	.lf_per_score_box_score .lf_per_score_2 {
		width: 44px;
	}

	.lf_per_score_box_tit .lf_per_score_out_in,
	.lf_per_score_box_par .lf_per_score_out_in,
	.lf_per_score_box_score .lf_per_score_out_in {
		width: 66px;
	}

	.lf_per_score_box_tit .lf_per_score_tot,
	.lf_per_score_box_par .lf_per_score_tot,
	.lf_per_score_box_score .lf_per_score_tot {
		flex-grow: 1;
	}

	.lf_per_score_box_tit .lf_per_score_1 {
		color: #fff;
	}

	.lf_per_score_box_tit .lf_per_score_2 {
		color: #fff;
	}

	.lf_per_score_box_tit .lf_per_score_out_in {
		color: #fff;
		background-color: #95A6EC;
	}

	.lf_per_score_box_tit .lf_per_score_tot {
		color: #fff;
	}

	.lf_per_score_box_par .lf_per_score_1 {
		color: #fff;
	}

	.lf_per_score_box_par .lf_per_score_2 {
		color: #fff;
	}

	.lf_per_score_box_par .lf_per_score_out_in {
		color: #fff;

	}

	.lf_per_score_box_par .lf_per_score_tot {
		color: #fff;
		background-color: #0E1B4D;
	}

	.lf_per_score_box_score .lf_per_score_1 {
		color: #333;
		background-color: #F2F2F2;
	}

	.lf_per_score_box_score .lf_per_score_2 {
		color: #333;
	}

	.lf_per_score_box_score .lf_per_score_out_in {
		color: #333;
		background-color: #F2F2F2;
	}

	.lf_per_score_box_score .lf_per_score_tot {
		color: #fff;
		background-color: #0E1B4D;
	}

	.lf_pross_box {
		width: 200px;
		height: 10px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		overflow: hidden;
		margin: 10px auto;
		position: relative;
		left: 100px;
	}

	.lf_pross_box div {
		height: 100%;
	}

	.lf_pross_box div:nth-of-type(1) {
		background: rgba(227, 177, 2, 1);
	}

	.lf_pross_box div:nth-of-type(2) {
		background: rgba(183, 42, 71, 1);
	}

	.lf_pross_box div:nth-of-type(3) {
		background: rgba(83, 86, 91, 1);
	}

	.lf_pross_box div:nth-of-type(4) {
		background: rgba(2, 82, 135, 1);
	}

	.lf_pross_box div:nth-of-type(5) {
		background: rgba(88, 8, 195, 1);
	}

	.lf_pross_box div:nth-of-type(6) {
		background: #d7d7d7;
	}

	.lf_pross_box1 {
		width: 60px;
		height: 220px;
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 22px;
		left: 140px;
	}

	.lf_pross_box1 div {
		color: #333;
		font-size: 20px;
		text-align: right;
		height: 30px;
		line-height: 30px;
	}

	.lf_scorecc_box {
		border-radius: 10px;
		overflow: hidden;
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 520px;
		background: #fff;
	}

	.lf_scorecc_box_1 {
		background-color: #0066cc;
		color: #fff;
		font-weight: bold;
		width: 520px;
		display: flex;
		margin: 0 auto;
		flex-direction: row;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.lf_scorecc_box_1 div {
		width: 33.33%;
		height: 55px;
		font-size: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_scorecc_box_2 {
		display: flex;
		width: 520px;
		display: flex;
		margin: 0 auto;
		flex-direction: row;
		background-color: rgba(249, 249, 249, 1);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		margin-bottom: 10px;
	}

	.lf_scorecc_box_2 div {
		width: 33.33%;
		height: 60px;
		font-size: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_scorecc_box_2 div span:nth-of-type(1) {
		font-size: 30px;
		color: #333;
		font-weight: bold;
	}

	.lf_scorecc_box_2 div span:nth-of-type(2) {
		font-size: 24px;
		color: #666;
	}

	.lf_pie_box {
		display: flex;
		justify-content: center;
		border-top: 1px solid #DBDBDB;
		padding-top: 30px;
	}

	.lf_pie_ {
		width: 520px;
		position: relative;
	}


	.lf_round_C_T1_t {
		width: 1200px;
		margin: 0 auto 30px;
		background: inherit;
		background-color: rgba(239, 243, 246, 1);
		border: none;
		border-radius: 10px;
		padding-bottom: 24px;
	}

	.lf_round_C_T1_t_tit {
		width: 1120px;
		height: 80px;
		background: inherit;
		background-color: rgba(255, 255, 255, 0);
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 30px;
		color: #004377;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}

	.lf_stats_content {
		width: 1120px;
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		overflow: hidden;
		margin: 0 auto 20px;
	}

	.lf_stats_content .lf_stats_tit_t {
		background-color: rgba(0, 102, 204, 1);
	}

	.lf_stats_content .lf_stats_box_b {
		background-color: #fff;
	}

	.lf_stats_content .lf_stats_box_b,
	.lf_stats_content .lf_stats_tit_t {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #E9E9E9;
	}

	.lf_stats_content .lf_stats_tit_t div {
		display: flex;
		align-items: center;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 24px;
		color: #fff;
	}

	.lf_stats_content .lf_stats_box_b div {
		display: flex;
		align-items: center;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 24px;
		/* color: #fff; */
	}

	.lf_stats_content .lf_stats_box_b div:nth-of-type(1),
	.lf_stats_content .lf_stats_tit_t div:nth-of-type(1) {
		width: 250px;
		flex-shrink: 0;
	}
	.lf_stats_content .lf_stats_box_b div,
	.lf_stats_content .lf_stats_tit_t div {
		width: 110px;
		flex-shrink: 0;
		flex-grow: 1;
	}

	

	.lf_stats_content .lf_stats_box_b div:nth-of-type(1) {
		font-weight: 400;
	}

	.lf_stats_content .lf_stats_box_b:nth-last-of-type(1) {
		border-bottom: none;
	}

	.lf_tips_ {
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: 'Arial Normal', 'Arial';
		font-weight: 400;
		font-style: normal;
		color: #999999;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		margin-bottom: 6px;
	}

	@media screen and (max-width: 768px) {}
</style>