<template>
    <div class="lf_Top_1">
        <el-row style="width: 100%;">
            <el-col :xs="24" :sm="24" :md="24">
                <div v-if="HonePage=='login0'" class="lf_foot_1" style="height: 240px;align-items: flex-start;flex-direction: column;">
					<div class="lf_foot_1_1">
						<div class="lf_foot_1_l">
							<img src="../../src/assets/images/u2063.png" alt="">
							<div class="lf_foot_1_l_1">service@wifigolf.com</div>
							<div class="lf_foot_1_l_2">中国 · 辽宁 · 大连 · 沙河口区数码路北段</div>
						</div>
						<img class="lf_foot_1_r" src="../../src/assets/images/u52.jpg" alt="">
					</div>
                	
                	<text class="lf_tj_box">站长统计 | 今日IP[45] | 今日PV[156] | 昨日IP[0] | 昨日PV[0] | 当前在线[4]</text>
                </div>
				<div v-else class="lf_foot_1">
					<img src="../../src/assets/images/u2063.png" alt="">
					<text>{{langs.Copyrightby}}</text>
				</div>
            </el-col>
            <el-col :sm="24" :md="24">
                <div v-if="systemlang=='cn'" class="lf_foot_2">
					2012- {{year}} wifiGOLF 版权所有 
					<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备16016693号-1 </a>
					<a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch"><img src="http://www.wifigolf.com/Public/home/images/beianicon.png"> 辽公网安备 21020402000797号</a>
				</div>
				<div v-else-if="systemlang=='tw'" class="lf_foot_2">
					2012- {{year}} wifiGOLF 版權所有 
					<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">遼ICP備16016693號-1 </a>
					<a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch"><img src="http://www.wifigolf.com/Public/home/images/beianicon.png"> 遼公網安備 21020402000797號</a>
				</div>
				<div v-else class="lf_foot_2">
					2012- {{year}} wifiGOLF 版权所有 
					<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备16016693号-1 </a>
					<a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch"><img src="http://www.wifigolf.com/Public/home/images/beianicon.png"> 辽公网安备 21020402000797号</a>
				</div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
	import 'element-plus/theme-chalk/display.css';
	import axiosx from '../axios/postx';
    import axios from '../axios/request';
	import axios1 from '../axios/request1';
	import {
		wexinShare
	} from '@/assets/utils.js';
    import {
        getSystemLang,
        getLangAll
    } from '../lang';
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
				urlData:[],
                banner:{},
                cj:{},
				CLBflag:false,
				year:2023,
				fenxiangcontent:{
					title:"wifiGOLF",
					desc:"@wifiGOLF数据支持",
					imgUrl:"http://clpga.org/clpga.jpg"
				},
				match_id_name:''
				
            };
        },
        name: 'navs',
        props: {
            msg: String,
			HonePage: String,
        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
			// console.log(this.systemlang);
			let currentUrl = window.location.href;
			currentUrl = encodeURIComponent(currentUrl);
			this.year=new Date().toDateString().split(' ')[3];
			var that=this;
			setTimeout(function(){that.weixin()},1000);
			var mt_id=window.location.href.split('#')[0].split("MatchID=")[1];
			
			axios1('https://www.wifigolf.com/api5/index.php/v5.wifigolf.share.info.json',{otp:'info',mt_id:mt_id})
			.then((res)=>{
				let data = res.data.data;
				if(this.systemlang=='en'){
					this.fenxiangcontent.title=data.match.mt_en_name==''?data.match.mt_cn_name:data.match.mt_en_name;
					this.fenxiangcontent.desc=data.qc.cr_en_name==''?data.qc.cr_cn_name:data.qc.cr_en_name;
					this.fenxiangcontent.imgUrl=data.share_img;
				}else{
					this.fenxiangcontent.title=data.match.mt_cn_name;
					this.fenxiangcontent.desc=data.qc.cr_cn_name;
					this.fenxiangcontent.imgUrl=data.share_img;
				}
			});
        },
		methods:{
			weixin() {
				var that=this;
				//请求微信配置参数接口（获取签名），由后台给接口给
				var urls = window.location.href.split('#')[0];
				//看后台请求接口是get/post
				axios1('https://www.wifigolf.com/home/mobile/index.php/Home/Rank/wxsdk.html?url='+encodeURIComponent(urls)).then(res => {
					
					if (res.status == 200) {
						//微信加签
						var obj = {
							appId: res.data.appId,
							nonceStr: res.data.nonceStr,
							signature: res.data.signature,
							timestamp: res.data.timestamp,
							jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
						};
						//分享数据，这段主要是为了在hash模式下分享出去的链接不被浏览器截取，保证完全把链接分享出去
						//var navLang = localStorage.getItem('lang')||'cn';
						var url = window.location.href;
							//url = url.split('?')[0];   
							//url = window.location.href+'?&lang='+navLang+'&type='+that.tFlag;
							console.log('url---',url);
						// var shareWxLink = encodeURIComponent(url);加密
						let shareData = {
							title: that.fenxiangcontent.title+'【成绩直播】', // 分享标题
							desc:that.fenxiangcontent.desc+'@wifiGOLF数据支持',
							//link: res.data.linkUrl,
							link: url,
							imgUrl: that.fenxiangcontent.imgUrl // 分享图标
						};
						//引用
						wexinShare(obj, shareData);
						// alert('获取sdk成功！');
					} else {
						alert('获取sdk参数失败！');
					}
				});
			},
			
		}

    }
</script>
<style scoped>
    .lf_Top_1{
    	width: 100%;
    	flex-direction: column;
    	display: flex;
    	align-items: center;
    	justify-content: space-between;
    }
    .lf_foot_1{
		width: 100%;
		height: 50px;
		background: inherit;
		background-color: rgba(32, 34, 66, 1);
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		border-bottom: 1px solid #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Arial Normal', 'Arial';
		font-weight: 400;
		font-style: normal;
		font-size: 22px;
		color: #FFFFFF;
	}
	.lf_foot_1 img{
		width: 107px;
		height: 30px;
	}
	.lf_foot_1 text{
		position: relative;
		top: 6px;
		left: 4px;
	}
	.lf_foot_2{
		width: 100%;
		height: 50px;
		background: inherit;
		background-color: rgba(51, 51, 51, 1);
		border: none;
		border-left: 0px;
		border-right: 0px;
		border-bottom: 0px;
		border-radius: 0px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 18px;
	}
	.lf_foot_2 a{
		color: #fff;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: fit-content;
		padding: 0 2px;
	}
	.lf_foot_2 img{
		width: 20px;
		height: 20px;
	}
	
	.lf_foot_1_1{
		width: 1280px;
		margin: 0 auto;
		padding: 0 40px;
		display: flex;
		justify-content: space-between;
	}
	.lf_foot_1_l img{
		width: 200px;
		height: 53px;
		margin-top: 40px;
	}
	.lf_foot_1_l_1{
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 16px;
		color: #9094AC;
		line-height: 36px;
		margin-top: 30px;
	}
	.lf_foot_1_l_2{
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 16px;
		color: #9094AC;
		line-height: 36px;
		margin-top: 0px;
	}
	.lf_foot_1_r{
		width: 120px!important;
		height: 120px!important;
		margin-top: 55px;
	}
	.lf_foot_1 .lf_tj_box{
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 14px;
		color: #999999;
		text-align: center;
		line-height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	@media screen and (max-width: 768px) {
		
	}
</style>
