/* eslint-disable */
function getSystemLang() {//语言读取顺序：地址栏>cookie>系统
	var navLang = 'cn';
	var url = window.location.href; //获取当前url
	var cs = url.split('?')[1]; //获取?之后的参数字符串
	var cs_arr = cs.split('&'); //参数字符串分割为数组
	var cs = {};
	for (var i = 0; i < cs_arr.length; i++) { //遍历数组，拿到json对象
		cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
	}
	navLang = cs.lang;
	if (navLang == null) {
		navLang = localStorage.getItem('lang');
	}else if(localStorage.getItem('lang')==null){
		navLang = navigator.language.substring(0, 2);
	}


	if (navLang == 'en') {
		systemlang = 'en';
	} else if (navigator.language == 'zh-TW' || navLang == 'tw') {
		systemlang = 'tw';
	} else {
		systemlang = 'cn';
	}
	// console.log(systemlang)
	return systemlang;
}

function getLangAll(systemlang) {
	var tt = {};
	for (var key in langarr) {
		tt[key] = langarr[key][systemlang];
	}
	return tt;
}

var langarr = {
	PLAYOFF:{
		'cn': '加洞赛',
		'en': 'PLAYOFF',
		'tw': '加洞賽'
	},
	Favorite:{
		'cn': '关注的组',
		'en': 'Favorite',
		'tw': '關注的組'
	},
	AllPlayers:{
		'cn': '全部组',
		'en': 'All Players',
		'tw': '全部組'
	},
	ProjectedCutline:{
		'cn': '预测晋级线',
		'en': 'Projected Cutline',
		'tw': '預測晉級線'
	},
	MissedCut:{
		'cn': '晋级线',
		'en': 'Missed Cut',
		'tw': '晉級線'
	},
	//----------------界面显示文字 start------------------
	'Events': {
		'cn': '赛事',
		'en': 'Events',
		'tw': '賽事'
	},
	'About': {
		'cn': '关于',
		'en': 'About',
		'tw': '關於'
	},
	'MTScore': {
		'cn': '比赛成绩',
		'en': 'Score',
		'tw': '比賽成績'
	},
	'MTGroup': {
		'cn': '比赛分组',
		'en': 'Group',
		'tw': '比賽分組'
	},
	'DataCenter': {
		'cn': '数据中心',
		'en': 'Stats',
		'tw': '數據中心'
	},
	'text_di': {
		'cn': '第',
		'en': 'Round ',
		'tw': '第'
	},
	'text_lun': {
		'cn': '轮',
		'en': '',
		'tw': '輪'
	},
	'text_zu': {
		'cn': '组',
		'en': '',
		'tw': '組'
	},
	'login': {
		'cn': '登录',
		'en': 'Login',
		'tw': '登录'
	},
	'text_pl_country': {
		'cn': '国籍',
		'en': 'Country/Region',
		'tw': '國籍'
	},
	'text_player': {
		'cn': '球员',
		'en': 'Player',
		'tw': '球員'
	},
	'text_news': {
		'cn': '新闻',
		'en': 'News',
		'tw': '新聞'
	},
	'text_ranking': {
		'cn': '排名',
		'en': 'By Pos',
		'tw': '排名'
	},
	'text_ranking1': {
		'cn': '排名',
		'en': 'Pos',
		'tw': '排名'
	},
	'text_Thru': {
		'cn': '完成',
		'en': 'Thru',
		'tw': '完成'
	},
	'text_Totals': {
		'cn': '总杆',
		'en': 'Total',
		'tw': '總桿'
	},
	'text_Group': {
		'cn': '分组',
		'en': 'By Group',
		'tw': '分組'
	},
	'text_price': {
		'cn': '奖金',
		'en': 'Prize',
		'tw': '獎金'
	},
	'text_export': {
		'cn': '导出',
		'en': 'Export',
		'tw': '導出'
	},
	'text_TotalScore': {
		'cn': '总杆成绩',
		'en': 'TotalScore',
		'tw': '總桿成績'
	},
	'text_NetScore': {
		'cn': '净杆成绩',
		'en': 'NetScore',
		'tw': '淨桿成績'
	},
	'text_Net': {
		'cn': '净杆',
		'en': 'Net',
		'tw': '淨桿'
	},
	'text_HDCP': {
		'cn': '差点',
		'en': 'HDCP',
		'tw': '差點'
	},
	'text_Total': {
		'cn': '总码数',
		'en': 'Total',
		'tw': '總碼數'
	},
	'Numberofentries': {
		'cn': '参赛场',
		'en': 'Entered',
		'tw': '参赛场'
	},
	'Quit': {
		'cn': '退出',
		'en': 'Quit',
		'tw': '退出'
	},
	'Username': {
		'cn': '账号',
		'en': 'Username',
		'tw': '账号'
	},
	'Inputyourusername': {
		'cn': '请输入账号',
		'en': 'Input your username',
		'tw': '请输入账号'
	},
	'Password': {
		'cn': '密码',
		'en': 'Password',
		'tw': '密码'
	},
	'Inputyourpassword': {
		'cn': '请输入密码',
		'en': 'Input your password',
		'tw': '请输入密码'
	},
	'Home': {
		'cn': '首页',
		'en': 'Home',
		'tw': '首页'
	},
	'TournamentsSchedule': {
		'cn': '赛程',
		'en': 'Schedule',
		'tw': '賽程'
	},
	'News': {
		'cn': '新闻',
		'en': 'News',
		'tw': '新闻'
	},
	'Live': {
		'cn': '直播',
		'en': 'Live',
		'tw': '直播'
	},
	'Stats': {
		'cn': '技术统计',
		'en': 'Stats',
		'tw': '技術統計'
	},
	'Leaderboard': {
		'cn': '排行榜',
		'en': 'Leaderboard',
		'tw': '排行榜'
	},
	'Leaderboard1': {
		'cn': '成绩',
		'en': 'Leaderboard',
		'tw': '成绩'
	},
	'PointsRankings': {
		'cn': '排行榜',
		'en': 'Rankings',
		'tw': '排行榜'
	},
	'RealtimeScore': {
		'cn': '即时成绩',
		'en': 'Real-time Score',
		'tw': '即时成绩'
	},
	'OrderofMerit': {
		'cn': '女子中巡奖金榜',
		'en': 'Order of Merit',
		'tw': '女子中巡奖金榜'
	},
	'': {
		'cn': '新闻精选',
		'en': 'Selected News',
		'tw': '新闻精选'
	},
	'More': {
		'cn': '查看更多',
		'en': 'More',
		'tw': '查看更多'
	},
	'Enterprise': {
		'cn': '企业介绍',
		'en': 'About',
		'tw': '企业介绍'
	},
	'Field': {
		'cn': '参赛球员',
		'en': 'Field',
		'tw': '参赛球员'
	},
	'Inquiry': {
		'cn': '查询',
		'en': 'Inquiry',
		'tw': '查询'
	},
	'Age': {
		'cn': '年纪',
		'en': 'Age',
		'tw': '年纪'
	},
	'Height': {
		'cn': '身高',
		'en': 'Height',
		'tw': '身高'
	},
	'YearofTurningProfessional': {
		'cn': '转职时间',
		'en': 'Year of Turning Professional',
		'tw': '转职时间'
	},
	'BestAwards': {
		'cn': '最好成绩',
		'en': 'Best Awards',
		'tw': '最好成绩'
	},
	'Yards/Pars': {
		'cn': '比赛码数/标准杆',
		'en': 'Yards/Pars',
		'tw': '比赛码数/标准桿'
	},
	'Year': {
		'cn': '年份',
		'en': 'Year',
		'tw': '年份'
	},
	'Champion': {
		'cn': '冠军球员',
		'en': 'Champion',
		'tw': '冠军球员'
	},
	'RoundScore': {
		'cn': '每轮成绩',
		'en': 'Round Score',
		'tw': '每轮成绩'
	},
	'TotalScore': {
		'cn': '总杆数',
		'en': 'Total Score',
		'tw': '总桿数'
	},

	'Period': {
		'cn': '时间',
		'en': 'Period',
		'tw': '时间'
	},
	'WinstoPar': {
		'cn': '夺冠成绩',
		'en': 'Wins to Par',
		'tw': '夺冠成绩'
	},
	'Order': {
		'cn': '序号',
		'en': 'Order',
		'tw': '序號'
	},
	'SignUp': {
		'cn': '参赛报名',
		'en': 'Sign Up',
		'tw': '參賽報名'
	},
	'FactSheet': {
		'cn': '赛事信息',
		'en': 'Fact Sheet',
		'tw': '賽事信息'
	},
	'ydbxz': {
		'cn': '预定表下载',
		'en': 'Reservation table download',
		'tw': '預定表下載'
	},
	'none': {
		'cn': '无信息',
		'en': 'None',
		'tw': '無信息'
	},
	'ShGk': {
		'cn': '赛事概况',
		'en': 'Event Overview',
		'tw': '賽事概況'
	},
	'FactSheet1': {
		'cn': '赛事信息',
		'en': 'Tour Info',
		'tw': '賽事信息'
	},
	'PleaseSelect': {
		'cn': '请选择',
		'en': 'Please Select',
		'tw': '請選擇'
	},
	'SignedUpEvents': {
		'cn': '报名赛站',
		'en': 'Signed Up Events',
		'tw': '報名賽站'
	},
	'SignUp': {
		'cn': '正赛报名',
		'en': 'Sign Up',
		'tw': '正賽報名'
	},
	'Time': {
		'cn': '赛事时间',
		'en': 'Time',
		'tw': '賽事時間'
	},
	'Venue': {
		'cn': '赛事地点',
		'en': 'Venue',
		'tw': '賽事地點'
	},
	'EntryFee': {
		'cn': '参  赛  费',
		'en': 'Entry Fee',
		'tw': '參  賽  費'
	},
	'EntryFee1': {
		'cn': '正赛参赛费',
		'en': 'Entry Fee',
		'tw': '正賽參賽費'
	},
	'EntryDeadline': {
		'cn': '报名截止',
		'en': 'Entry Deadline',
		'tw': '報名截止'
	},
	'ChineseName': {
		'cn': '中  文  名',
		'en': 'Chinese Name',
		'tw': '中  文  名'
	},
	'EnglishName': {
		'cn': '英  文  名',
		'en': 'English Name',
		'tw': '英  文  名'
	},
	'Nationality': {
		'cn': '所属国籍',
		'en': 'Nationality',
		'tw': '所屬國籍'
	},
	'IDNumber': {
		'cn': '身份证号',
		'en': 'ID Number',
		'tw': ''
	},
	'MobileNumber': {
		'cn': '手机号码',
		'en': 'Mobile Number',
		'tw': ''
	},
	'EmailAddress': {
		'cn': '电子邮箱',
		'en': 'Email Address',
		'tw': ''
	},
	'Identity': {
		'cn': '身       份',
		'en': 'Identity',
		'tw': ''
	},
	'Attribution': {
		'cn': '代表球会/企业',
		'en': 'Attribution',
		'tw': ''
	},
	'Bank': {
		'cn': '银       行',
		'en': 'Bank',
		'tw': ''
	},
	'NameofAccountBank': {
		'cn': '开户行名称',
		'en': 'Name of Account Bank',
		'tw': ''
	},
	'BankAccountNumber': {
		'cn': '银行账号',
		'en': 'Bank Account Number',
		'tw': ''
	},
	'InvoiceTitle': {
		'cn': '发票名头',
		'en': 'Invoice Title',
		'tw': ''
	},
	'TaxIdentificationNumber': {
		'cn': '税       号',
		'en': 'Tax Identification Number',
		'tw': ''
	},
	'CGAMembershipNumber': {
		'cn': '中高协会员',
		'en': 'CGA Membership Number',
		'tw': ''
	},
	'RelatedService': {
		'cn': '相关服务',
		'en': 'Related Service',
		'tw': ''
	},
	'Hotel': {
		'cn': '酒        店',
		'en': 'Hotel',
		'tw': ''
	},
	'OfficialHotel': {
		'cn': '入住官方酒店',
		'en': 'Official Hotel',
		'tw': ''
	},
	'OfficialHotel1': {
		'cn': '官方酒店',
		'en': 'Official Hotel',
		'tw': ''
	},
	'SelfArrangement': {
		'cn': '自己安排',
		'en': 'Self Arrangement',
		'tw': ''
	},
	'Caddie': {
		'cn': '球        童',
		'en': 'Caddie',
		'tw': ''
	},
	'CourseProvided': {
		'cn': '球会安排',
		'en': 'Club Caddie',
		'tw': ''
	},
	'SelfProvided': {
		'cn': '自带球童',
		'en': 'Own Caddie',
		'tw': ''
	},
	'Submit': {
		'cn': '提交',
		'en': 'Submit',
		'tw': ''
	},
	'Theorderhasbeensubmitted': {
		'cn': '您已经提交订单',
		'en': 'The order has been submitted',
		'tw': ''
	},
	'': {
		'cn': '参赛报名名单',
		'en': 'Field List',
		'tw': ''
	},
	'AllOrders': {
		'cn': '全部订单',
		'en': 'All Orders',
		'tw': ''
	},
	'WaitingforApproval': {
		'cn': '等待审核',
		'en': 'Waiting for Approval',
		'tw': ''
	},
	'WaitingforPayment': {
		'cn': '等待支付',
		'en': 'Waiting for Payment',
		'tw': ''
	},
	'Completed': {
		'cn': '已完成',
		'en': 'Completed',
		'tw': ''
	},
	'Attention': {
		'cn': '特别提醒',
		'en': 'Attention',
		'tw': ''
	},
	'OrderNumber': {
		'cn': '订单号',
		'en': 'Order Number',
		'tw': ''
	},
	'EventName': {
		'cn': '赛事名称',
		'en': 'Event Name',
		'tw': ''
	},
	'EventNameService': {
		'cn': '收费项目',
		'en': 'Pay Service',
		'tw': ''
	},
	'AmountYuan': {
		'cn': '金额(元)',
		'en': 'Amount (Yuan)',
		'tw': ''
	},
	'StatusofOrder': {
		'cn': '订单状态',
		'en': 'Status of Order',
		'tw': ''
	},
	'EventPeriod': {
		'cn': '比赛时间',
		'en': 'Event Period',
		'tw': ''
	},
	'DeadlinePaymentDate': {
		'cn': '最晚支付时间',
		'en': 'Deadline Payment Date',
		'tw': ''
	},
	'OrderTime': {
		'cn': '订单时间',
		'en': 'Order Time',
		'tw': ''
	},
	'Operation': {
		'cn': '操作',
		'en': 'Operation',
		'tw': ''
	},
	'PrintOrder': {
		'cn': '打印订单',
		'en': 'Print Order',
		'tw': ''
	},
	'': {
		'cn': '订单下载打印',
		'en': 'Order Download and Print',
		'tw': ''
	},
	'': {
		'cn': '下载中.....',
		'en': 'Downloading…',
		'tw': ''
	},
	'RefundConfirm': {
		'cn': '确认需要退款操作',
		'en': 'Refund Confirm',
		'tw': ''
	},
	'OperationConfirm': {
		'cn': '操作确认',
		'en': 'Operation Confirm',
		'tw': ''
	},
	'Confirm': {
		'cn': '确定',
		'en': 'Confirm',
		'tw': ''
	},
	'Cancel': {
		'cn': '取消',
		'en': 'Cancel',
		'tw': ''
	},
	'': {
		'cn': '提交成功',
		'en': 'Submittted Successfully',
		'tw': ''
	},
	'': {
		'cn': '微信扫码付款',
		'en': 'Wechat QR Code Scan Payment',
		'tw': ''
	},
	'PaidSuccessfully': {
		'cn': '支付成功',
		'en': 'Paid Successfully ',
		'tw': ''
	},
	'SelecttheMethodofPayment': {
		'cn': '请选择支付方式',
		'en': 'Select the Method of Payment',
		'tw': ''
	},
	'': {
		'cn': '微信',
		'en': 'Wechat',
		'tw': ''
	},
	'': {
		'cn': '支付宝',
		'en': 'Alipay',
		'tw': ''
	},
	'PickupDropoffService': {
		'cn': '接送机预定',
		'en': 'Pick-up/Drop-off Service',
		'tw': ''
	},
	'PickupService': {
		'cn': '接机预定',
		'en': 'Pick-up Service',
		'tw': ''
	},
	'DropoffService': {
		'cn': '送机预定',
		'en': 'Drop-off Service',
		'tw': ''
	},
	'NameoftheEntriedEvent': {
		'cn': '报名赛事名称',
		'en': 'Name of the Entried Event',
		'tw': ''
	},
	'EventPeriod': {
		'cn': '比赛时间',
		'en': 'Event Period',
		'tw': ''
	},
	'PickupInfo': {
		'cn': '接机信息填写',
		'en': 'Pick-up Info',
		'tw': ''
	},
	'DropoffInfo': {
		'cn': '送机机信息填写',
		'en': 'Drop-off Info',
		'tw': ''
	},
	'NoneedforPickup': {
		'cn': '无需接机',
		'en': 'No need for Pick-up',
		'tw': ''
	},
	'NoneedforDropoff': {
		'cn': '无需送机',
		'en': 'No need for Drop-off',
		'tw': ''
	},
	'CourseIntroduction': {
		'cn': '球场介绍',
		'en': 'Course',
		'tw': ''
	},
	'CourseName': {
		'cn': '球场名称',
		'en': 'Course Name',
		'tw': ''
	},
	'CourseLocation': {
		'cn': '球场地点',
		'en': 'Course Location',
		'tw': ''
	},
	'TotalLength': {
		'cn': '球场总长度',
		'en': 'Total Length',
		'tw': '球场总长度'
	},
	'CourseWebsiteLink': {
		'cn': '球场官网友情链接',
		'en': 'Course Website Link',
		'tw': '球场官网友情链接'
	},
	'text_Yard': {
		'cn': '码',
		'en': 'Yards',
		'tw': '碼'
	},
	'Hole': {
		'cn': '洞号',
		'en': 'Hole',
		'tw': '洞號'
	},
	'Yards': {
		'cn': '码数',
		'en': 'Yards',
		'tw': '碼數'
	},
	'Description': {
		'cn': '文字描述',
		'en': 'Description',
		'tw': '文字描述'
	},
	'Pictures': {
		'cn': '图片展示',
		'en': 'Pictures',
		'tw': '圖片展示'
	},
	'TeeTimes': {
		'cn': '分组表',
		'en': 'Tee Times',
		'tw': '分组表'
	},
	'SubjecttotheLocalTimeZone': {
		'cn': '海外赛事以当地时区为准',
		'en': 'Subject to the Local Time Zone',
		'tw': '海外赛事以当地时区为准'
	},
	'Gruop': {
		'cn': '组',
		'en': 'Group',
		'tw': '组'
	},
	'Start': {
		'cn': '开球时间',
		'en': 'Start',
		'tw': '开球时间'
	},
	'Tee': {
		'cn': '发球台',
		'en': 'Tee',
		'tw': '发球台'
	},
	'Amateur': {
		'cn': '(A) 业余选手',
		'en': '(A) Amateur',
		'tw': '(A) 業餘選手'
	},
	'StartfromBacknine': {
		'cn': '*  选手从后九洞开球',
		'en': '* Start from Back 9',
		'tw': '*  選手從後九洞開球'
	},
	'Confirmed': {
		'cn': '选手成绩已确认',
		'en': 'Confirmed',
		'tw': '選手成績已確認'
	},
	'Unconfirmed': {
		'cn': '选手成绩未确认',
		'en': 'Unconfirmed',
		'tw': '選手成績未確認'
	},
	'WoninPlayoff': {
		'cn': '延长赛获胜选手',
		'en': 'Won in Playoff',
		'tw': '延長賽獲勝選手'
	},
	'DownloadPDF': {
		'cn': 'PDF下载',
		'en': 'Download PDF',
		'tw': 'PDF下載'
	},
	'DownloadExcel': {
		'cn': 'Excel下载',
		'en': 'Download Excel',
		'tw': 'Excel下載'
	},
	'Today': {
		'cn': '本轮',
		'en': 'Today',
		'tw': '本輪'
	},
	'Score': {
		'cn': '杆数',
		'en': 'Score',
		'tw': '桿数'
	},
	'Putts': {
		'cn': '推杆',
		'en': 'Putts',
		'tw': '推桿'
	},
	'Drive': {
		'cn': '发球',
		'en': 'Drive',
		'tw': '發球'
	},
	'Accuracy': {
		'cn': '准确率',
		'en': 'Accuracy',
		'tw': '准确率'
	},
	'Bunker': {
		'cn': '沙坑',
		'en': 'Bunker',
		'tw': '沙坑'
	},
	'Save': {
		'cn': '救球率',
		'en': 'Save',
		'tw': '救球率'
	},
	'Average': {
		'cn': '平均',
		'en': 'Average',
		'tw': '平均'
	},
	'DrivingDistance': {
		'cn': '开球距离',
		'en': 'Driving Distance',
		'tw': '開球距離'
	},
	'text_Par': {
		'cn': '标准杆',
		'en': 'Par',
		'tw': '標準桿'
	},
	'GIR': {
		'cn': '上果岭率',
		'en': 'GIR',
		'tw': '上果嶺率'
	},
	'PerRound': {
		'cn': '每轮',
		'en': 'Per Round',
		'tw': '每輪'
	},
	'OnePutt': {
		'cn': '一推杆',
		'en': 'One-Putt',
		'tw': '一推桿'
	},
	'GIR1': {
		'cn': '标准杆上果岭',
		'en': 'GIR',
		'tw': '標准桿上果嶺率'
	},
	'AveragePutting': {
		'cn': '平均推杆',
		'en': 'Average Putting',
		'tw': '平均推桿'
	},
	'Scrambling': {
		'cn': '未标准杆上果岭',
		'en': 'Scrambling',
		'tw': '未標准桿上果嶺率'
	},
	'EntryOrder': {
		'cn': '参赛报名订单',
		'en': 'Entry Order',
		'tw': '參賽報名名單'
	},
	'OtherOrder': {
		'cn': '其他订单',
		'en': 'Other Order',
		'tw': '其他訂單'
	},
	'TotalPurse': {
		'cn': '赛事总奖金',
		'en': 'Total Purse',
		'tw': '賽事總獎金'
	},
	'WatchLiveStream': {
		'cn': '直播中',
		'en': 'LIVE',
		'tw': '直播中'
	},
	'TourInfo': {
		'cn': '巡回赛信息',
		'en': 'Tour Info',
		'tw': '巡回賽信息'
	},
	'Sh': {
		'cn': '赛事',
		'en': 'Match',
		'tw': '賽事'
	},
	'Score': {
		'cn': '成绩',
		'en': 'Score',
		'tw': '成績'
	},
	'News': {
		'cn': '新闻',
		'en': 'News',
		'tw': '新聞'
	},
	'Pictures': {
		'cn': '图片',
		'en': 'Pictures',
		'tw': '圖片'
	},
	'Video': {
		'cn': '视频',
		'en': 'Video',
		'tw': '視頻'
	},
	'': {
		'cn': '精彩新闻',
		'en': 'News Highlights',
		'tw': '精彩新聞'
	},
	'PleaseSelecttheYear': {
		'cn': '请选择年份',
		'en': 'Please Select the Year',
		'tw': '請選擇年份'
	},
	'PleaseSelecttheEvent': {
		'cn': '请选择赛事',
		'en': 'Please Select the Event',
		'tw': '請選擇賽事'
	},
	'PleaseInput': {
		'cn': '请输入球员名称',
		'en': "Please Input Player's Name",
		'tw': '求輸入球員名'
	},
	'Rounds1': {
		'cn': '统计场',
		'en': 'of Events',
		'tw': '統計場'
	},
	'SGTotal': {
		'cn': '综合',
		'en': 'SG Total',
		'tw': '綜合'
	},
	'Rounds': {
		'cn': '参赛场',
		'en': 'Entered',
		'tw': '參賽場'
	},
	'ThePrevious': {
		'cn': '上一场',
		'en': 'The Previous',
		'tw': '上一場'
	},
	'Ongoing': {
		'cn': '进行中',
		'en': 'Ongoing…',
		'tw': '進行中'
	},
	'TheNext': {
		'cn': '下一场',
		'en': 'The Next',
		'tw': '下一場'
	},
	'EventNameLocation': {
		'cn': '赛事名称/赛事地点',
		'en': 'Event Name/Location',
		'tw': '賽事名稱/賽事地點'
	},
	'DefendingChampionPrize1': {
		'cn': '卫冕冠军/冠军',
		'en': 'Defending Champion/Champion',
		'tw': '衛冕冠軍/冠軍'
	},
	'DefendingChampionPrize2': {
		'cn': '赛事总奖金',
		'en': 'Prize',
		'tw': '賽事總獎金'
	},
	'text_ren': {
		'cn': '人',
		'en': '',
		'tw': '人'
	},
	'Averageintegral': {
		'cn': '平均积分',
		'en': 'Average',
		'tw': '平均積分'
	},
	'Applyforrefund': {
		'cn': '申请退款',
		'en': 'Apply for refund',
		'tw': '申請退款'
	},
	'resstpassword': {
		'cn': '忘记密码',
		'en': 'Forgot Password',
		'tw': '忘記密碼'
	},
	'enteraccount': {
		'cn': '请先输入账号！',
		'en': 'Please enter the account number first!',
		'tw': '請先輸入賬號'
	},
	"ts1": {
		'cn': '密码错误，是否需要重置',
		'en': 'Password is wrong;Do you want to reset the password?',
		'tw': '密碼錯誤，是否需要重置'
	},
	"ts2": {
		'cn': '新密码已发送至',
		'en': 'New password has been sent to ',
		'tw': '新密碼已發送至'
	},
	"ts3": {
		'cn': '邮箱',
		'en': '',
		'tw': '郵箱'
	},
	"ts4": {
		'cn': '您的邮箱不存在,请联系管理员',
		'en': 'Your mailbox does not exist. Please contact the administrator',
		'tw': ''
	},
	"ts5": {
		'cn': '发送失败',
		'en': 'Fail in send',
		'tw': ''
	},
	"ts6": {
		'cn': "系统将重置密码，发送至您的邮箱",
		'en': 'Do you want to send the new password to ',
		'tw': ''
	},
	"ts7": {
		'cn': "，确定重置吗？",
		'en': '?',
		'tw': ''
	},
	'text_ChangePassword': {
		'cn': '修改密码',
		'en': 'Change Password',
		'tw': ''
	},
	'text_Newpassword': {
		'cn': '输入新密码',
		'en': 'New password',
		'tw': ''
	},
	'text_Newpasswordagain': {
		'cn': '再次输入新密码',
		'en': 'New password again',
		'tw': ''
	},
	'text_signup': {
		'cn': '报名',
		'en': 'sign up',
		'tw': ''
	},
	'oPayment': {
		'cn': '支付',
		'en': 'Pay',
		'tw': ''
	},
	'oCancel': {
		'cn': '取消订单',
		'en': 'cancel',
		'tw': ''
	},
	'text_Signupinfo': {
		'cn': '报名信息',
		'en': 'Signup info',
		'tw': ''
	},
	'text_Playersname': {
		'cn': '球员姓名',
		'en': "Player's name",
		'tw': ''
	},
	'text_IDtype': {
		'cn': '证件类型',
		'en': 'ID type',
		'tw': ''
	},
	'text_IDnumber': {
		'cn': '证件号码',
		'en': 'ID number',
		'tw': ''
	},
	'text_Gender': {
		'cn': '性别',
		'en': 'Gender',
		'tw': ''
	},
	'text_Pro': {
		'cn': '职业',
		'en': 'Pro',
		'tw': ''
	},
	'text_Am': {
		'cn': '业余',
		'en': 'Am',
		'tw': ''
	},
	'text_Nationality': {
		'cn': '国籍',
		'en': 'Nationality',
		'tw': ''
	},
	'text_Birthdate': {
		'cn': '出生日期',
		'en': 'Birth date',
		'tw': ''
	},
	'text_date': {
		'cn': '日期',
		'en': 'Date',
		'tw': ''
	},
	'text_Selectdate': {
		'cn': '选择日期',
		'en': 'Select date',
		'tw': ''
	},
	'text_Relationtype': {
		'cn': '与被监护人关系',
		'en': 'Relation type',
		'tw': ''
	},
	'text_Guardiansname': {
		'cn': '监护人姓名',
		'en': "Guardian's name"
	},
	'text_Contactinfo': {
		'cn': '监护人联系方式',
		'en': 'Contact info',
		'tw': ''
	},
	'text_Countrycode': {
		'cn': '国际区号',
		'en': 'Country code',
		'tw': ''
	},
	'text_IDofWorldWomensgolf': {
		'cn': '世界女子高尔夫排名ID （四位数字）',
		'en': "ID of World Women's golf rankings (4 digits)"
	},
	'text_Airportpickupinfo': {
		'cn': '接机信息',
		'en': 'Airport pickup info',
		'tw': ''
	},
	'text_Airportdropoffinfo': {
		'cn': '送机信息',
		'en': 'Airport drop-off info',
		'tw': ''
	},
	'text_Noticearrangementsforpickup': {
		'cn': '通知：接送机安排将于近期公布，望周知。',
		'en': 'Notice: arrangements for pick-up and drop-off will be announced in the near future.',
		'tw': ''
	},
	'text_Ihavereadtheevent': {
		'cn': '我已阅读赛事相关信息并清楚报名费支付成功后不予退还',
		'en': ' I have read the event information and know that the entry fee is non-refundable after successful payment',
		'tw': ''
	},
	'text_Submitandpayforthesignup': {
		'cn': '提交并支付报名',
		'en': 'Submit and pay for the signup',
		'tw': ''
	},
	'text_Signup': {
		'cn': '参赛报名',
		'en': 'Sign up',
		'tw': ''
	},
	'text_Signupinfo1': {
		'cn': '报名查询',
		'en': 'Signup info',
		'tw': ''
	},
	'WaitingforPaymentN': {
		'cn': '提交支付',
		'en': 'Pay',
		'tw': ''
	},
	'text_Submit': {
		'cn': '确认查询',
		'en': 'Submit',
		'tw': ''
	},
	'text_back': {
		'cn': '返回',
		'en': 'Back',
		'tw': ''
	},
	'WaitingforPaymentN': {
		'cn': '提交支付',
		'en': 'Pay',
		'tw': ''
	},
	'notsame': {
		'cn': '两次输入的密码不相同',
		'en': 'passwords different',
		'tw': ''
	},
	'nve': {
		'cn': '女',
		'en': 'girl',
		'tw': ''
	},
	'look': {
		'cn': '查看',
		'en': 'Details',
		'tw': ''
	},
	'PersonalScore': {
		'cn': '个人成绩',
		'en': 'Personal Score',
		'tw': ''
	},
	'TeamScore': {
		'cn': '团队成绩',
		'en': 'Team Score',
		'tw': ''
	},
	'text_IDtype_select': {
		'cn': '请选择证件类型',
		'en': 'Please select a certificate type',
		'tw': ''
	},
	'cardpayTs': {
		'cn': '等待付款，请勿关闭此页面，关闭后无法接收支付状态',
		'en': 'Please do not close this page while waiting for payment. Payment status cannot be accepted after closing',
		'tw': ''
	},

	'MatchTsnr_c': {
		'cn': '没有进行中比赛',
		'en': 'Unprocessed Competitions',
		'tw': ''
	},
	'MatchTsnr_o': {
		'cn': '暂无已完结的比赛',
		'en': 'No Completed Matches',
		'tw': ''
	},
	'MatchTsnr_f': {
		'cn': '暂无未来比赛',
		'en': 'No Future Competitions',
		'tw': ''
	},
	'cj_zd': {
		'cn': '逐洞成绩',
		'en': 'Hole by hole',
		'tw': ''
	},
	'cj_lxb': {
		'cn': '领先榜',
		'en': 'Leaderboard',
		'tw': ''
	},
	'cj_tm': {
		'cn': '团队成绩',
		'en': 'Team Score',
		'tw': ''
	},
	'tj_date': {
		'cn': '统计至',
		'en': 'RANKINGS AS OF',
		'tw': ''
	},
	'yi':{
		'cn':'一',
		'en':'1',
		'tw':'一'
	},
	'er':{
		'cn':'二',
		'en':'2',
		'tw':'二'
	},
	'san':{
		'cn':'三',
		'en':'3',
		'tw':'三'
	},
	'si':{
		'cn':'四',
		'en':'4',
		'tw':'四'
	},
	'wu':{
		'cn':'五',
		'en':'5',
		'tw':'五'
	},
	'1':{
		'cn':'一',
		'en':'1',
		'tw':'一'
	},
	'2':{
		'cn':'二',
		'en':'2',
		'tw':'二'
	},
	'3':{
		'cn':'三',
		'en':'3',
		'tw':'三'
	},
	'4':{
		'cn':'四',
		'en':'4',
		'tw':'四'
	},
	'5':{
		'cn':'五',
		'en':'5',
		'tw':'五'
	},
	'gerensai':{
		'cn':'个人赛',
		'en':'Personal',
		'tw':'個人賽'
	},
	'tuanduisai':{
		'cn':'团队赛',
		'en':'5','Team':'團隊賽'
	},
	'BGS':{
		'cn':'个人总杆',
		'en':'Stroke Play',
		'tw':'個人總桿'
	},
	'jinggan':{
		'cn':'个人净杆',
		'en':'Net Stroke Play',
		'tw':'個人净桿'
	},
	'DFS':{
		'cn':'个人定分',
		'en':'Stableford',
		'tw':'個人定分'
	},
	'BDS':{
		'cn':'个人比洞',
		'en':'Match Play',
		'tw':'個人比洞'
	},
	'QNCJ':{
		'cn':'团队总杆',
		'en':'Team Stroke Play',
		'tw':'團體總桿'
	},
	'TNET':{
		'cn':'团队净杆',
		'en':'Team Net Stroke Play',
		'tw':'團體净桿'
	},
	'TNET-NNP':{
		'cn':'团队自报差点',
		'en':'Team Handicap Play',
		'tw':'團體自報差點'
	},
	'TDDF':{
		'cn':'团队定分',
		'en':'Team Stableford',
		'tw':'團體定分'
	},
	'NNP':{
		'cn':'新新贝利亚',
		'en':'New Peoria',
		'tw':'新新貝利亞'
	},
	'ZBCD':{
		'cn':'自报差点',
		'en':'Handicap Play',
		'tw':'自報差點'
	},
	'NNP_ZBCD':{
		'cn':'自报差点',
		'en':'Handicap Play',
		'tw':'自報差點'
	},
	'SRSQ':{
		'cn':'四人四球',
		'en':'Four-balls',
		'tw':'四人四球'
	},
	'SRLQ':{
		'cn':'四人两球',
		'en':'Foursomes',
		'tw':'四人兩球'
	},
	'LRLQ':{
		'cn':'单人对抗',
		'en':'Singles',
		'tw':'單人對抗'
	},
	'sqw':{
		'cn':'双旗位',
		'en':'Power Play',
		'tw':'雙旗位'
	},
	'qn':{
		'cn':'取前',
		'en':'Top ',
		'tw':'取前'
	},
	'qzh':{
		'cn':'取最好',
		'en':'Best score',
		'tw':'取最好'
	},
	'qgh':{
		'cn':'取杆和',
		'en':'Gross score per Hole',
		'tw':'取桿和'
	},
	'zdqn':{
		'cn':'逐洞取前',
		'en':'Top X per Hole',
		'tw':'逐洞取前'
	},
	'zdqzh':{
		'cn':'逐洞取最好',
		'en':'Best Ball',
		'tw':'逐洞取最好'
	},
	'zdqgh':{
		'cn':'逐洞取杆和',
		'en':'Gross score per Hole',
		'tw':'逐洞取桿和'
	},
	'ChoosetheviewofExcel':{
		'cn':'请选择导出表格',
		'en':'Choose the view of Excel',
		'tw':'請選擇導出表格'
	},
	'Copyrightby':{
		'cn':'版权所有',
		'en':' ©Copyright',
		'tw':'版權所有'
	},
	'Scantoviewscores':{
		'cn':'扫码手机看成绩',
		'en':'View by phone',
		'tw':'掃碼手機看成績'
	},
	'text_leader':{
		'cn':'领先榜',
		'en':'Leader',
		'tw':'領先榜'
	},
	'text_perhole':{
		'cn':'逐洞成绩',
		'en':'Per Hole',
		'tw':'逐洞成績'
	},
	'Pos.':{
		'cn':'第',
		'en':'Pos.',
		'tw':'第'
	} ,
	'ming':{
		'cn':'名',
		'en':'',
		'tw':'名'
	} ,
	'text_team':{
		'cn':'团队',
		'en':'Team',
		'tw':'團隊'
	} ,
	'ge':{'cn':'个','en':'','tw':'個'} ,
	'Eagle':{'cn':'老鹰','en':'Eagle','tw':'老鷹'} ,
	'Birdie':{'cn':'小鸟','en':'Birdie','tw':'小鳥'} ,
	'Par':{'cn':'帕','en':'Par','tw':'帕'} ,
	'Bogey':{'cn':'柏忌','en':'Bogey','tw':'柏忌'} ,
	'D.Bogey':{'cn':'双柏忌','en':'D.Bogey','tw':'雙柏忌'} ,
	'Other':{'cn':'其他','en':'Other','tw':'其他'},
	'StartHole':{'cn':'出发','en':'Start','tw':'出發'},
	'defen':{'cn':'得分','en':'Point','tw':'得分'},
	'zongdefen':{'cn':'总得分','en':'Total Point','tw':'總得分'},
	'dangqianlun':{'cn':'当前轮','en':'Round Point','tw':'當前輪'},
	'text_jieduan':{'cn':'阶段','en':'','tw':'階段'}
};


// exports.getLang = getLang;
exports.getLangAll = getLangAll;
exports.getSystemLang = getSystemLang;