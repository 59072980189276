<template>
    <div class="lf_Top_1">
        <el-row style="width: 100%;">
            <el-col :xs="24" :sm="8" :md="12">
				<view style="display: flex;align-items: center;">
					<el-breadcrumb separator=">">
					  <el-breadcrumb-item @click="goWfigolf('home')">
							<img style="width: 20px;height: 20px;margin: 0 10px 0 20px;display: block;" src="../assets/images/u889.svg" alt="">
							<img style="width: 95px;height: 36px;display: block;" src="../../src/assets/images/u894.png" alt="">
					  </el-breadcrumb-item>
					  <el-breadcrumb-item @click="goWfigolf('customer')">{{urlData.matchInfo?urlData.matchInfo.clubName:''}}</el-breadcrumb-item>
					  <el-breadcrumb-item @click="goWfigolf('tour')">{{urlData.matchInfo?urlData.matchInfo.tourName:''}}</el-breadcrumb-item>
					 
					</el-breadcrumb>
					<view class="lf_sszj_box" v-if="0">
						<view class="lf_sszj_box_1">赛事直击</view>
						<view class="lf_sszj_box_2">起点中巡赛厦门东方站</view>
					</view>
				</view>
            </el-col>
            <el-col :sm="12" :md="12">
                <div class="lf_nav_r_box">
				  <div @click="goWfigolf('about')">{{langs.About}}</div>
				  <div class="lf_nav_change_L">
					  <div @click="ShowCLB" class="lf_nav_change_L_1">
						  {{systemlang=='cn'?'中文':''}}
						  {{systemlang=='en'?'English':''}}
						  {{systemlang=='tw'?'繁體':''}}
						  <img :style="CLBflag?'transform: rotate(0deg);':'transform: rotate(180deg);'" src="../../src/assets/images/u946.png" alt="">
					  </div>
					  <div v-if="CLBflag" class="lf_nav_change_L_2">
						  <div :class="systemlang=='cn'?'lf_L_select':'1'" @click="ChangeL('cn')">中文</div>
						  <div :class="systemlang=='tw'?'lf_L_select':'2'" @click="ChangeL('tw')">繁體</div>
						  <div :class="systemlang=='en'?'lf_L_select':'3'" @click="ChangeL('en')">English</div>
					  </div>
				  </div>
				  <img @click="goWfigolf('login')" src="../../src/assets/images/u888.svg" mode=""/>
				  <img @click="goWfigolf('home')" src="../../src/assets/images/u890.svg" mode="">
                </div>
            </el-col>
        </el-row>
		
    </div>
	
	
</template>

<script>
	import 'element-plus/theme-chalk/display.css';
	import axiosx from '../axios/postx';
    import axios from '../axios/request';
    import {
        getSystemLang,
        getLangAll
    } from '../lang';
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
				urlData:[],
                banner:{},
                cj:{},
				CLBflag:false,
				  
				  show: true,
				  isMove:false,
            };
        },
        name: 'navs',
        props: {
            msg: String,

        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
			// console.log(this.systemlang);
			let currentUrl = window.location.href;
			currentUrl = encodeURIComponent(currentUrl);
			//获取banner
			axiosx('apply/geturl.json',{url:currentUrl,type:'1'})
				.then((res)=>{
					let data = res.data;
					
					if(data.msgCode=='200')
					{
						
						let surl = this.urlData = data.data;
						if(surl.redirct.type == 'old')
						{
							//window.open(surl.redirct.url, '_self');
						}
					}
					else
					{
					
					}
				});

        },
		  
		methods:{
			ChangeL(a){
				 
				this.systemlang=a;
				this.langs=getLangAll(a);
				localStorage.setItem('lang',a);
				var params = this.$route.query;
				params["lang"] = a;
				let routeUrl = this.$router.resolve({
					path: "/playerscore",
					query: params
				});
				// console.log(routeUrl.href);
				window.open(routeUrl.href, '_self');
				// window.location.reload();
			},
			ShowCLB(){
				this.CLBflag=!this.CLBflag;
			},
			goWfigolf($falg){
				var data = this.urlData;
				console.log(data)
				if(data[$falg])
				{
					window.open(data[$falg], '_self');
				}
				else
				{
					console.log('未找到地址');
				}
			},
			
		}

    }
</script>
<style>
	.el-breadcrumb__inner{
		display: flex;
		align-items: center;
		color: #fff!important;
	}
	.el-breadcrumb__separator{
		color: #fff;
	}
</style>
<style scoped>
    .lf_Top_1{
    	width: 100%;
    	height: 50px;
    	background-color: rgba(51, 51, 51, 1);
    	display: flex;
    	align-items: center;
    	justify-content: space-between;
		position: relative;
    }
    .lf_Top_1 .el-breadcrumb{
    	font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
    	font-weight: 700;
    	font-style: normal;
    	font-size: 18px!important;
    	color: #FFFFFF!important;
		display: flex;
		align-items: center;
		height: 50px;
    }
    .lf_nav_r_box{
		height: 50px;
    	display: flex;
    	align-items: center;
    	padding-right: 24px;
    	font-family: 'Arial Normal', 'Arial';
    	font-weight: 400;
    	font-style: normal;
    	font-size: 18px;
    	color: #FFFFFF;
		justify-content: flex-end;
		
    }
    .lf_nav_r_box div{
    	margin: 0 10px;
		cursor: pointer;
		height: 100%;
		display: flex;
		align-items: center;
    }
	.lf_nav_change_L{
		position: relative;
		width: 120px;
		margin: unset!important;
	}
	.lf_nav_change_L_1{
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 120px;
		margin: unset!important;
	}
	.lf_nav_change_L_1 img{
		width: 14px!important;
		height: 8px!important;
		margin: unset!important;
	}
	.lf_nav_change_L_2{
		position: absolute;
		height: fit-content!important;
		top: 50px;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-color: rgba(51, 51, 51, 0.9);
		margin: unset!important;
		z-index: 999;
	}
	.lf_nav_change_L_2 div{
		width: 120px;
		height: 50px!important;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: unset;
		flex-shrink: 0;
		border-bottom: 1px solid #fff;
	}
	.lf_nav_change_L_2 div:nth-last-of-type(1){
		border-bottom: unset;
	}
	.lf_nav_change_L_2 .lf_L_select{
		font-weight: bold;
	}
    .lf_nav_r_box img{
    	width: 20px;
    	height: 20px;
    	margin: 0 20px;
		cursor: pointer;
    }
	
	.lf_sszj_box{
		width: 440px;
		height: 40px;
		border: 1px solid #9B9B9B;
		border-radius: 20px;
		display: flex;
		align-items: center;
	}
	.lf_sszj_box_1{
		width: 90px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		color: #FFFFFF;
		background-color: rgba(253, 125, 69, 1);
		margin: 0 5px;
		border-radius: 50px;
	}
	.lf_sszj_box_2{
		font-family: '微软雅黑';
		    font-weight: 400;
		    font-style: normal;
		    font-size: 18px;
		    color: #FFFFFF;
			margin-left: 5px;
	}
	
	@media screen and (max-width: 768px) {
		
	}
</style>
