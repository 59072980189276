<template>
    <el-row style="width: 100%;">
        <el-col :xs="24" :md="24" class="lf_el-col">
			<div class="lf_score_box">
				<yard :match="match" :matchhole="matchhole"></yard>
				<div class="lf_score_bgzg_box">
					<div  id="lf_score_bgzg_box_tit" :class="searchBarFixed == true ? 'isFixed lf_score_bgzg_box_tit' :'lf_score_bgzg_box_tit'">
						<div class="lf_score_pos">{{langs.Gruop}}</div>
						<div class="lf_score_pos_player">{{langs.text_player}}</div>
						<div class="lf_score_wc">{{langs.text_Thru}}</div>
						<div class="lf_score_cj">{{langs.Score}}</div>
						<div class="lf_score_9" v-for="(i,index) in [1,2,3,4,5,6,7,8,9]">
							<div>{{matchhole.hole[i-1].ho_id}}</div>
							<div>{{matchhole.hole[i-1].par}}</div>
						</div>
						<div class="lf_score_out_in">
							<div>OUT</div>
							<div>{{ outpar }}</div>
						</div>
						<div class="lf_score_9" v-for="(i,index) in [10,11,12,13,14,15,16,17,18]">
							<div>{{matchhole.hole[i-1].ho_id}}</div>
							<div>{{matchhole.hole[i-1].par}}</div>
						</div>
						<div class="lf_score_out_in">
							<div>IN</div>
							<div>{{ inpar }}</div>
						</div>
						<div class="lf_score_rd">
							<div>R{{ rd_id }}</div>
							<div>{{matchhole.par}}</div>
						</div>
						<div class="lf_score_zg_zg">{{langs.text_Totals}}</div>
					</div>
					<template v-for="isguanzhu in [1,2]">
						<div class="lf_selected_group_box" v-if="isguanzhu==1&&matchguanzhu!=0">{{langs.Favorite}} </div>
						<div class="lf_selected_group_box" v-if="isguanzhu==2&&matchguanzhu!=0">{{langs.AllPlayers}} </div>
						<template v-for="group in grouplist">
							<div class="lf_score_bgzg_box_con" v-if="(isguanzhu==1&&guanzhuarr.indexOf(mt_id+'_'+group[0].groupDetail.gp_seq)!=-1)||isguanzhu==2">
								<div class="lf_select_group">
									{{ group[0].groupDetail.gp_seq }}
									<img @click="setguanzhu(group[0].groupDetail.gp_seq)" v-if="guanzhuarr.indexOf(mt_id+'_'+group[0].groupDetail.gp_seq)!=-1" src="../../assets/images/u4551.png" alt="">
									<img @click="setguanzhu(group[0].groupDetail.gp_seq)" v-if="guanzhuarr.indexOf(mt_id+'_'+group[0].groupDetail.gp_seq)==-1" src="../../assets/images/u4552.png" alt="">
								</div>
								<div class="lf_lf_score_pos_player_right">
									<div class="lf_lf_score_pos_player_right_con" v-for="(item,index) in group" :style="match_set.match_tm==1?'height:80px':'height:60px'">
										<div class="lf_score_pos_player">
											<!-- <img class="lf_country_img" src="../../assets/images/u3.png" alt=""> -->
											<!-- <span class="lf_country_text">CHN</span> -->
											<img v-if="match_set.mt_country_show_type==1" class="lf_country_img" :src="'http://overview.wifigolf.com/images/flags/changurl.php?mt_id='+mt_id+'&content=64/'+ (item.player!=undefined&&item.player.pl_country&&item.player.pl_country!='*'?item.player.pl_country:'country')+'.png'" alt="">
											<span v-if="match_set.mt_country_show_type==2" class="lf_country_text">{{item.player!=undefined&&item.player.pl_country?item.player.pl_country:''}}</span>
											<!-- <div class="lf_country_player_text">{{langs.text_player}}姓名</div> -->
											<div class="lf_country_player_text" @click="openplayerscoredetail(item.pl_id)">{{ item.player!=undefined?item.player.pl_cn_name:"" }}{{ item.groupDetail!=undefined&&item.groupDetail.gp_start_hole>9&&item.groupDetail.gp_start_hole<18?'*':'' }}{{ match_set.mt_amateur=='(A)'&&item.player.pl_type=='AMA'?'(A)':'' }}</div>
											<!-- <div class="lf_country_tee_text">蓝</div> -->
											<div v-if="match_set.tee_show_type==1" class="lf_country_tee_text">{{ item.matchPlayer!=undefined?item.matchPlayer.mp_teename.split("")[0]:"" }}</div>
											<!-- <div class="lf_country_sex_box">
												<img class="lf_country_sex_img" src="../../assets/images/u7.png" alt="">
												<img class="lf_country_sex_img" src="../../assets/images/u9.png" alt="">
											</div> -->
											<div v-if="((match_set.mt_sex_show_type=='male'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==1))||((match_set.mt_sex_show_type=='female'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==0))" class="lf_country_sex_box">
												<img v-if="(match_set.mt_sex_show_type=='male'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==1)" class="lf_country_sex_img" src="../../assets/images/u7.png" alt="">
												<img v-if="(match_set.mt_sex_show_type=='female'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==0)" class="lf_country_sex_img" src="../../assets/images/u9.png" alt="">
											</div>
											
											<!-- <img class="lf_country_zzs_img" src="../../assets/images/u1.png" alt="">
											<div class="lf_country_club_text">湖南奥林匹克高尔夫俱乐部</div> -->
											<img v-if="item.matchPlayer!=undefined&&item.matchPlayer.mp_logo!=''&&item.matchPlayer.mp_logo!=null" class="lf_country_zzs_img" :src="'http://overview.wifigolf.com/club/adm/images/matches/'+mt_id+'/'+item.matchPlayer.mp_logo" alt="">
											<div class="lf_country_club_text" v-if="item.matchPlayer!=undefined&&item.matchPlayer.teamname!=undefined">{{ item.matchPlayer.teamname.team_ch_name }}</div>	
										</div>
										<!-- <div class="lf_score_wc lf_score_wc_F">F</div> -->
										<div :class="item.playerSummary!=undefined&&item.playerSummary.sts=='C'?'lf_score_wc_F':''" class="lf_score_wc">{{item.playerSummary!=undefined&&item.playerSummary.thru!=0?(item.playerSummary.thru==18?"F":item.playerSummary.thru):item.groupDetail.gp_start_time}}</div>
										<!-- <div class="lf_score_cj lf_score_2 lf_score_3 lf_score_4">-2</div> -->
										<div class="lf_score_cj" :class="item.playerSummary==undefined?'':(item.playerSummary.totalto_par<0?'lf_score_other_2':(item.playerSummary.totalto_par==0?'lf_score_other_3':'lf_score_other_4'))">{{item.playerSummary!=undefined?(item.playerSummary.totalto_par>0?("+"+item.playerSummary.totalto_par):(item.playerSummary.totalto_par==0?'E':item.playerSummary.totalto_par)):""}}</div>
										<!-- <div class="lf_score_9 lf_score_1 lf_score_2 lf_score_3 lf_score_4 lf_score_5" v-for="(i,index) in [1,2,3,4,5,6,7,8,9]">
											7
										</div> -->
										<div class="lf_score_9" :class="item.playerScore==undefined||item.playerScore[i-1]==undefined||item.playerScore[i-1].score==''?'':(item.playerScore[i-1].to_par<=-2?lf_score_1:(item.playerScore[i-1].to_par==-1?lf_score_2:(item.playerScore[i-1].to_par==0?lf_score_3:(item.playerScore[i-1].to_par==1?lf_score_4:lf_score_5))))" v-for="(i,index) in [1,2,3,4,5,6,7,8,9]">
											{{item.playerScore!=undefined&&item.playerScore[i-1]!=undefined?item.playerScore[i-1].score:""}}
										</div>
										<!-- <div class="lf_score_out_in">33</div> -->
										<div class="lf_score_out_in">{{item.playerSummary!=undefined?item.playerSummary.out:""}}</div>
										<!-- <div class="lf_score_9" v-for="(i,index) in [1,2,3,4,5,6,7,8,9]">
											2
										</div> -->
										<div class="lf_score_9" :class="item.playerScore==undefined||item.playerScore[i-1]==undefined||item.playerScore[i-1].score==''?'':(item.playerScore[i-1].to_par<=-2?lf_score_1:(item.playerScore[i-1].to_par==-1?lf_score_2:(item.playerScore[i-1].to_par==0?lf_score_3:(item.playerScore[i-1].to_par==1?lf_score_4:lf_score_5))))" v-for="(i,index) in [10,11,12,13,14,15,16,17,18]">
											{{item.playerScore!=undefined&&item.playerScore[i-1]!=undefined?item.playerScore[i-1].score:""}}
										</div>
										<!-- <div class="lf_score_out_in">33</div> -->
										<div class="lf_score_out_in">{{item.playerSummary!=undefined?item.playerSummary.in:""}}</div>
										<!-- <div class="lf_score_rd lf_score_2 lf_score_3 lf_score_4">
											66
										</div> -->
										<!-- <div class="lf_score_rd lf_score_2 lf_score_3 lf_score_4">
											{{item.playerSummary!=undefined?item.playerSummary.score:""}}
										</div> -->
										<div v-if="item.playerSummary!=undefined&&item.playerSummary.thru>=this.matchhole.hole.length" class="lf_score_rd" :class="item.playerSummary==undefined?'':(item.playerSummary.to_par<0?'lf_score_other_2':'lf_score_other_3')">
											{{item.playerSummary!=undefined?item.playerSummary.score:""}}
										</div>
										<div v-else class="lf_score_rd" :class="item.playerSummary==undefined?'':(item.playerSummary.to_par<0?'lf_score_other_2':(item.playerSummary.to_par==0?'lf_score_other_3':'lf_score_other_4'))">
											{{item.playerSummary!=undefined&&item.playerSummary.thru!=0?(item.playerSummary.to_par>0?("+"+item.playerSummary.to_par):(item.playerSummary.to_par==0?'E':item.playerSummary.to_par)):""}}
										</div>
										<!-- <div class="lf_score_zg_zg">222</div> -->
										<div class="lf_score_zg_zg">{{item.playerSummary!=undefined?item.playerSummary.totalscore:""}}</div>
									</div>
								</div>
							</div>
						</template>
					</template>
				</div>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	 import axiosx from '../../axios/postx';
	 import axios from '../../axios/request';
	 import yard from '../yard.vue';
	 import addHole from '../addHole.vue';
	 import { getSystemLang, getLangAll } from "../../lang";
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤'
				}],
				value: '',
				outpar:'',
				inpar:'',
				mt_id:'',
				
				guanzhu:'',
				grouplist:'',
				matchguanzhu:0,
				guanzhuarr:[],
				lf_score_1:"lf_score_1",
				lf_score_2:"lf_score_2",
				lf_score_3:"lf_score_3",
				lf_score_4:"lf_score_4",
				lf_score_5:"lf_score_5",
				searchBarFixed:false,
				HtmloffsetTop:0
		    }
		},
        name: 'Group',
        props: {
            msg: String,
            flag:String,
			cd:String  ,//菜单
			undergoing:String,
			list:Object,
			playoff:Object,
			match:Object,
			match_set:Object,
			matchhole:Object,
			rd_id:String
        },
		components: {
		    yard,
			addHole
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			// this.rd_id = this.match_set.rd_id;
			this.mt_id = this.match_set.mt_id;
			for(var k in this.match_set.mt_show_style){
				if(k == 0){
					this.lf_score_1 = this.match_set.mt_show_style[k];
				}else if(k == 1){
					this.lf_score_2 = this.match_set.mt_show_style[k];
				}else if(k == 2){
					this.lf_score_3 = this.match_set.mt_show_style[k];
				}else if(k == 3){
					this.lf_score_4 = this.match_set.mt_show_style[k];
				}else if(k == 4){
					this.lf_score_5 = this.match_set.mt_show_style[k];
				}
			}
			this.changepar();
			var list = this.list;
			var grouplist = [];
			var gp_seq = 0;
			var temp = [];
			for(var k in list){
				if(gp_seq!=list[k].groupDetail.gp_seq){
					if(temp.length>0){
						grouplist.push(temp);
					}
					temp = [];
					gp_seq = list[k].groupDetail.gp_seq;
				}
				list[k].groupDetail.gp_start_time = list[k].groupDetail.gp_start_time!=null&&list[k].groupDetail.gp_start_time!='0000-00-00 00:00:00'?gethm(list[k].groupDetail.gp_start_time):"";
				temp.push(list[k]);
			}
			if(temp.length>0){
				grouplist.push(temp);
			}
			this.grouplist = grouplist;
			this.showguanzhu();
		},
		watch:{
			rd_id:function(nval,oval){
				this.rd_id = nval;
			},
			list:function(nval,oval){
				var list = nval;
				var grouplist = [];
				var gp_seq = 0;
				var temp = [];
				for(var k in list){
					if(gp_seq!=list[k].groupDetail.gp_seq){
						if(temp.length>0){
							grouplist.push(temp);
						}
						temp = [];
						gp_seq = list[k].groupDetail.gp_seq;
					}
					list[k].groupDetail.gp_start_time = list[k].groupDetail.gp_start_time!=null&&list[k].groupDetail.gp_start_time!='0000-00-00 00:00:00'?gethm(list[k].groupDetail.gp_start_time):"";
					temp.push(list[k]);
				}
				if(temp.length>0){
					grouplist.push(temp);
				}
				this.grouplist = grouplist;
				this.showguanzhu();
				this.$nextTick(()=>{
					this.$parent.setscorecolor();
				});
			},
			playoff:function(nval,oval){
				
			}
		},
		mounted(){
			this.$parent.setscorecolor();
		window.addEventListener('scroll', this.handleScroll);
		var div = document.getElementById('lf_score_bgzg_box_tit');
		this.HtmloffsetTop = div.getBoundingClientRect().top;
		},
		destroyed () {
		 window.removeEventListener('scroll', this.handleScroll)
		},
		methods:{
			handleScroll () {
			 var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			 //console.log(scrollTop);
			  console.log(this.HtmloffsetTop);
			  if (scrollTop > this.HtmloffsetTop) {
			  this.searchBarFixed = true
			  } else {
			  this.searchBarFixed = false
			  }
			},
			changepar(){
				var outpar = 0;
				var inpar = 0;
				for(var k in this.matchhole.hole){
					if(this.matchhole.hole[k].ho_id<=9){
						outpar+=this.matchhole.hole[k].par;
					}else if(this.matchhole.hole[k].ho_id<=18&&this.matchhole.hole[k].ho_id>9){
						inpar+=this.matchhole.hole[k].par;
					}
				}
				this.outpar = outpar;
				this.inpar = inpar;
			},
			showguanzhu(){
				var guanzhu = localStorage.getItem("guanzhu");
				if(guanzhu != null){
					this.guanzhu = guanzhu;
					this.guanzhuarr = guanzhu.split(",");
				}
				this.matchguanzhu = 0;
				for(var k in this.grouplist){
					if(this.guanzhuarr.indexOf(this.mt_id+'_'+this.grouplist[k][0].groupDetail.gp_seq)!=-1){
						this.matchguanzhu++;
					}
				}
			},
			setguanzhu(seq){
				var guanzhu = localStorage.getItem("guanzhu");
				var str = this.mt_id+"_"+seq
				if(guanzhu == null){
					guanzhu = [];
					guanzhu.push(str);
					localStorage.setItem("guanzhu",guanzhu.join(","))
				}else{
					guanzhu = guanzhu.split(",");
					var index = guanzhu.indexOf(str);
					if(index==-1){
						if(guanzhu[0] == ""){
							guanzhu = [];
						}
						guanzhu.push(str);
						localStorage.setItem("guanzhu",guanzhu.join(","))
					}else{
						guanzhu.splice(index,1);
						localStorage.setItem("guanzhu",guanzhu.join(","))
					}
				}
				this.showguanzhu();
			},
			openplayerscoredetail(pl_id){
				var mt_id = this.mt_id;
				var clubid = this.match.club_id;
				var tourid = this.match.tour_id;
				// var url = "http://overview.wifigolf.com/customer/playerScore.php?lang=1&ClubID="+clubid+"&TourID="+tourid+"&MatchID="+mt_id+"&s=match&Pl_id="+pl_id;
				var url = "score?lang=cn&ClubID="+clubid+"&TourID="+tourid+"&MatchID="+mt_id+"&s=match&Pl_id="+pl_id;
				window.open(url);
			}
		}
    }
	function gethm(time){
		return time.substring(11,16);
	}
</script>
<style>
	html {
	-webkit-filter: grayscale(100%);
	filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
	}
	.lf_daochu_type_box .el-input,.el-select{
		height: 100%;
		border-radius: 4px;
		font-size: 22px;
		border: unset;
	}
	.lf_daochu_type_box .select-trigger{
		height: 100%;
	}
	.lf_el-col .lf_daochu_type_box .el-input.is-focus,.lf_el-col .lf_daochu_type_box .el-input.is-focus .el-input__wrapper,.lf_el-col .lf_daochu_type_box .el-input__wrapper{
		box-shadow: unset!important;
		background-color: unset;
	}
	.lf_el-col .el-input__inner{
		font-size: 16px;
	}
	
	.lf_zgjg_btn_box_right .el-input,.el-select{
		height: 100%;
		border-radius: 4px;
		font-size: 22px;
		border: unset;
	}
	.lf_zgjg_btn_box_right .select-trigger{
		height: 100%;
	}
	.lf_el-col .lf_zgjg_btn_box_right .el-input.is-focus,.lf_el-col .lf_zgjg_btn_box_right .el-input.is-focus .el-input__wrapper,.lf_el-col .lf_zgjg_btn_box_right .el-input__wrapper{
		box-shadow: unset!important;
		background-color: unset;
	}
	.lf_el-col .el-input__inner{
		font-size: 16px;
	}
	
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .lf_score_box{
		max-width: 1280px;
		margin: 0 auto;
	}
	.lf_tips_box_A{
		height: 50px;
		display: flex;
		align-items: center;
		font-size: 18px;
		color: #333;
	}
	.lf_tips_box_A div{margin-right: 20px;}
	.lf_tips_box_A div span{
		color: #027512;
	}
	.lf_zgjg_btn_box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1280px;
		height: 40px;
		background: inherit;
		background-color: rgba(240, 243, 246, 1);
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
}
	.lf_zgjg_btn_box_left{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.lf_zgjg_btn_box_left div{
		max-width: 120px;
		height: 40px;
		margin-left: 20px;
		font-size: 22px;
		color: #333;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_zgjg_btn_box_left div img{
		display: none;
	}
	.lf_zgjg_btn_box_left .lf_zgjg_btn_box_left_select{
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 22px;
		color: #0066CC;
		text-align: center;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_zgjg_btn_box_left .lf_zgjg_btn_box_left_select img{
		position: absolute;
		bottom: 0;
		width: 16px;
		height: 8px;
		display: block;
	}
	
	.lf_score_bgzg_box{
		width: 1280px;
		margin: 20px auto;
	}
	.lf_score_bgzg_box_tit{
		display: flex;
		align-items: center;
		background-color: #F0F3F6;
		height: 60px;
	}
	.lf_score_bgzg_box_tit div{
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		color: #333;
	}
	.lf_score_bgzg_box_con{
		display: flex;
		align-items: center;
		background-color: #fff;
	}
	.lf_score_bgzg_box_con div{
		display: flex;
		align-items: center;
		font-size: 24px;
	}
	.lf_score_bgzg_box_con:nth-of-type(2n+1){
		background-color: #fafafa;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player{
		position: relative;
		padding-left: 10px;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_club_text{
		position: absolute;
		bottom: 0;
		height: 22px;
		font-family: '微软雅黑 Light', '微软雅黑 Regular', '微软雅黑';
		font-weight: 250;
		font-style: normal;
		font-size: 16px;
		color: #55565B;
		line-height: 22px;
		width: 100%;
		overflow: hidden;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_img{
		width: 30px;
		height: 20px;
		margin-right: 8px;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_player_text{
		    font-family: '微软雅黑';
		    font-weight: 400;
		    font-style: normal;
		    font-size: 22px;
		    color: #000000;
		    text-align: left;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_text{
		    font-family: 'Arial Narrow', 'Arial';
		        font-weight: 400;
		        font-style: normal;
		        font-size: 20px;
		        color: #666666;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_sex_box{
		width: 24px;
		height: 24px;
		background: inherit;
		background-color: rgba(238, 238, 238, 1);
		border: none;
		border-radius: 24px;
		margin: 0 2px;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_sex_img{
		width: 16px;
		height: 16px;
		margin: 0 2px;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_zzs_img{
		width: 80px;
		height: 60px;
		margin: 0 2px;
	}
	.lf_score_bgzg_box_con .lf_score_pos_player .lf_country_tee_text{
		width: 24px;
		    height: 24px;
		    background: inherit;
		    background-color: rgba(238, 238, 238, 1);
		    border: none;
		    border-radius: 24px;
		    -moz-box-shadow: none;
		    -webkit-box-shadow: none;
		    box-shadow: none;
		    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		    font-weight: 700;
		    font-style: normal;
		    font-size: 14px;
			margin: 0 2px;
	}
	.lf_score_pos{
		width: 60px;
		height: 100%;
		justify-content: center;
	}
	.lf_score_pos_c{
		width: 40px;
		height: 100%;
		display: flex;
		flex-direction: row;
		font-size: 18px;
	}
	.lf_pos_change_lv{
		color: #027512;
	}
	.lf_pos_change_red{
		color: #B72A47;
	}
	.lf_score_pos_player{
		width: 270px;
		height: 100%;
		flex-grow: 1;
	}
	.lf_score_wc{
		width: 80px;
		height: 100%;
		font-size: 24px;
		color: #000000;
	}
	.lf_score_wc_F{
		font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
		font-weight: 700;
		font-style: normal;
		font-size: 24px;
		color: #027512;
	}
	.lf_score_cj{
		width: 60px;
		height: 100%;
		font-weight: bold;
	}
	.lf_score_9{
		width: 32px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.lf_score_9 div{
		width: 32px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_bgzg_box_tit .lf_score_9 div:nth-of-type(1){
		background-color: #0E1B4D;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_9 div:nth-of-type(2){
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_out_in div:nth-of-type(1){
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_out_in div:nth-of-type(2){
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_rd div:nth-of-type(1){
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_rd div:nth-of-type(2){
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_bgzg_box_tit .lf_score_zg_zg{
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_score_out_in{
		width: 50px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.lf_score_out_in div{
		width: 50px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_rd{
		width: 50px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.lf_score_rd div{
		width: 50px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_zg_zg{
		width: 50px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_score_pos_c img{
		width: 5px;
		height: 12px;
	}
	.lf_score_bgzg_box_con .lf_score_out_in{
		background-color: #F0F3F6;
	}
	.lf_score_bgzg_box_con .lf_score_zg_zg{
		background-color: #95A6EC;
		color: #fff;
	}
	.lf_select_group{
		width: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		    color: #000000;
	}
	.lf_select_group img{
		width: 30px;
		height: 30px;
		margin-top: 5px;
	}
	.lf_lf_score_pos_player_right{
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.lf_lf_score_pos_player_right_con{
		width: 100%;
	}
	.lf_selected_group_box{
		width: 1280px;
		height: 30px;
		background: inherit;
		background-color: rgba(255, 88, 96, 1);
		border: none;
		border-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		color: #FFFFFF;
		text-align: left;
		padding-left: 20px;
		font-size: 22px;
	}
	.lf_selected_group_box span{
		font-size:20px;
	}
    @media screen and (max-width: 768px) {
        
    }
</style>
